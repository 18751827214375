<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    
    <section >
        <div class="register-container">
        <div class="register-form-container">
            <form id="editProfile"  novalidate autocomplete="off">
                <div class="heading">
            <span>Local Government Online Payment Management System</span>
            <h3>Edit Your Profile Details</h3>
        </div>
                
                <div class="form-group">
                    <label for="uName">your name</label> 
                    <input type="text" name="uName" id="uName" v-model="proObj.name" class="form-control">
                    <p v-if="errorObj.nameErr.length > 0">{{ errorObj.nameErr[0] }}</p>
                    
                </div>
            
                <div class="form-group">
                    <label for="uPhone">your phone number</label>
                    <input type="text" name="uPhone" id="uPhone" v-model="proObj.phone" class="form-control">
                    <p v-if="errorObj.phoneErr.length > 0">{{ errorObj.phoneErr[0] }}</p>
                    
                </div>
                
                <div class="form-group">
                    <label for="uAddress">Your address</label>
                    <input type="text" name="uAddress" id="uAddress" v-model="proObj.address" class="form-control">
                    <p v-if="errorObj.addErr.length > 0">{{ errorObj.addErr[0] }}</p>
                </div>
                <div class="form-group">
                    <label for="uEmail">Your email</label>
                    <input type="email" name="uEmail" id="uEmail" v-model="proObj.email" class="form-control">
                    <p v-if="errorObj.emailErr.length > 0">{{ errorObj.emailErr[0] }}</p>
                </div>
                <div class="form-group">
                    <input type="button" value="Update" class="btn" @click="handleSubmit" />
                   
                </div>
            </form>
        </div>
    </div>
    </section>
  
</template>

<script>
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert';
import { mapState, mapMutations } from "vuex";
export default {
    name: "Profile",

    data() {
        return {
            proObj: { name: "", phone: "", address: "", email: "" },
            errorObj: { nameErr: [], phoneErr: [], addErr: [], emailErr: []},
            matchProfile:undefined,
            matchUser:undefined,
            theProfile:[],
           cus_nic:'',Nic:'',
        }
    },

   
        
    computed: {
        ...mapState(["user"])
    },
    created(){
        // this.getSessionData()
        this.showAUser()
        this.getSessionData();
    }, 
    methods: {
        ...mapMutations(["setUser"]),
        async MatchUser(nic) {
            let data = await axios.get('/customersdata/' + nic);
            this.matchUser = data.data;
        },
        async getSessionData(){
    
        const data=JSON.parse(sessionStorage.getItem('Userdata'));
        if(data){
            this.Nic=data.Unic
        }
            await this.MatchUser(this.Nic);
            this.setUser(this.matchUser);
        },
        async showAUser() {
        // let data = this.user.cus_nic;
        this.theProfile= (await axios.get('/customersdata/' +this.user.cus_nic)).data;
        

        this.proObj.name = this.theProfile.cus_name
        this.proObj.phone = this.theProfile.cus_contact
        this.proObj.address = this.theProfile.cus_address
        this.proObj.email = this.theProfile.cus_email
        
        
     
   },
   async updateProfileMatch(id) {
           
           let data = await axios.get('/customersdata/' + id );
           this.matchProfile = data.data;
       },

        resetCheckErr: function () {
            this.errorObj.nameErr = [];
            this.errorObj.phoneErr = [];
            this.errorObj.addErr = [];
            this.errorObj.emailErr = [];
            
        },

        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },

        checkForm: function () {
            this.resetCheckErr();

            // Name validate
            if (!this.proObj.name) {
                this.errorObj.nameErr.push("Entering a name is required");
            }
            else {
                if (!/^[A-Za-z]+$/.test(this.proObj.name.replace(/\s/g, ""))) {
                    this.errorObj.nameErr.push('A name can only contain letters');
                }
            }

            // Phone validate
            if (!this.proObj.phone) {
                this.errorObj.phoneErr.push('Entering phone number is required');
            }
            else {
                // if (!this.proObj.phone.startsWith('94')) {
                //     this.errorObj.phoneErr.push('Phone numbers must start with 84');
                // }
                if (this.registerObj.phone.length != 11) {
                    this.errorObj.phoneErr.push('Phone numbers must have exactly 11 digits');
                }

                if (!/[0-9]{11}/.test(this.registerObj.phone)) {
                    this.errorObj.phoneErr.push('Phone numbers can only contain numbers');
                }
            }
            // Address Validate
            if (!this.proObj.address) {
                this.errorObj.addErr.push("Entering a name is required");
            }

            // Email validate
            if (!this.proObj.email) {
                this.errorObj.emailErr.push("Entering a email is required");
            }
            else {
                if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(this.proObj.email)) {
                    this.errorObj.emailErr.push('Email must be valid');
                }
            }
            //  // Pass validate
            //  if (!this.proObj.pass) {
            //     this.errorObj.passErr.push('Password is required');
            // }
            // else {
            //     if (!/[!@#$%^&*]/.test(this.proObj.pass)) {
            //         this.errorObj.passErr.push('Password must contain at least 1 special character');
            //     }

            //     if (this.proObj.pass.length < 8) {
            //         this.errorObj.passErr.push('Password must be more than or equal 8 characters');
            //     }
            // }

          
        },
      
        async handleSubmit() {
            // this.checkForm();

            // if (!this.checkEmptyErr()) {
            //     e.preventDefault();
            // } else {
                // e.preventDefault();
                if(!this.user){
                        this.$refs.alert.showAlert('error','Please Loging to Continue !')
                        this.$router.push("/login");
                    }
                    else{
                await this.updateProfileMatch(this.user.cus_nic);
                    if(!this.matchProfile){
                       
                           this.$refs.alert.showAlert("Something went wrong");
                           
                    } 
                    else{
                        let data = {
                            cus_name: this.proObj.name,
                            cus_contact: this.proObj.phone,
                            cus_address: this.proObj.address,
                            cus_email: this.proObj.email,
                           
                        };
                    await axios.put("/customersupdate/" + (this.user.cus_nic),data,)
                   
                        this.$refs.alert.showAlert('success', 'Successfully Updated !');
                  
                       
                    }
                }

     
        //    / }
        }
    },

    components: {
        VueBasicAlert
    }

}
</script>

<style scoped>
.order-section {
    padding: 2rem 9%;
}

.order-section .icons-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.order-section .icons-container .icons {
    border-radius: .5rem;
    padding: 2rem;
    text-align: center;
    background: #f7f7f7;
}

.order-section .icons-container .icons img {
    height: 10rem;
}

.order-section .icons-container .icons h3 {
    font-size: 2rem;
    color: #130f40;
    margin-top: .5rem;
}

.order-section form {
    background: #f7f7f7;
    padding: 2rem;
    border-radius: .5rem;
}

.order-section form .row {
    justify-content: space-between;
}

.order-section form .row .input-box {
    width: 49%;
    padding: 1.8rem 0;
}

.order-section form .row label {
    font-size: 1.7rem;
    color: #666;
}

.order-section form .row p {
    font-size: 1.5rem;
    position: absolute;
    color: rgb(243, 47, 47);
    margin: 0;
    padding-top: 5px;
}

.order-section form .row input,
.order-section form .row textarea {
    width: 100%;
    margin-top: .5rem;
    padding: 1rem 1.2rem;
    width: 100%;
    border-radius: .5rem;
    font-size: 1.6rem;
    text-transform: none;
    color: #130f40;
}

.order-section form .row textarea {
    height: 20rem;
    resize: none;
}

.order-section form .row .map {
    height: 100%;
    width: 100%;
    border-radius: .5rem;
}

@media (max-width: 768px) {
    .order form .row .input-box {
        width: 100%;
    }

    .order-section form .row {
        display: block;
        max-width: 100%;
        width: 100%;
        margin: 0;
    }

    .order-section form .row .input-box {
        width: 100%;
    }

}

@media (max-width: 576px) {
    .order-section .icons-container {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    }
}


.register-container {
    padding: 1.5rem 9%;
     min-height: 80vh;
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.3rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    padding: 2rem 1.2rem;
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: none;
}

.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    padding-left: 5px;
    padding-right: 40px;
    color: #620a0a;
}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 30%;
    text-align: center;
    align-items: center;
    background: #620a0a;
    
}

.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #620a0a;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 100%;

}
</style>