/* eslint-disable */  
<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <section>
    
    <div class="register-container">
        <div class="register-form-container">
            <form id="ShopRentForm" @submit="handleSubmit" novalidate autocomplete="off" >
                <div class="heading">
            <span>Local Government Online Payment Management System</span>
            <h3><b>කඩ කුලී ගෙවීම්</b></h3>
            </div>
                
                <div class="form-group">
                    <label id="uProvince"><b>Select Province | පළාත තෝරන්න:</b>
                    </label>
                       
                            <select class="form-control" @change="changeprovince"   v-model="rentObj.pro"  name="province" id="province">
                                <option value="" selected disabled>Choose</option>
                                <option v-for="f in province" v-bind:key="f.pro_id" v-bind:value="f.pro_code">{{ f.pro_name }} </option>
                                
                                
                            </select>
                                
                          
                                <!-- <p><span>User country: {{rentObj.pro }}</span></p> -->
                
                    <p class="error-mess" v-if="errorObj.proErr.length > 0">{{ errorObj.proErr[0] }}</p>

                    
                </div>
            
                <div class="form-group">
                    <label id="uDistrict"><b>Select District | දිස්ත්‍රික්කය තෝරන්න:</b>
                    </label>
                    
                            <select class="form-control" @change="changedist" name="district" id="district" v-model="rentObj.dis" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ds in Districts" :key="ds.dist_code" :value="ds.dist_code">{{ ds.dist_name }} </option>
                            </select>
                                
                          
                                <!-- <p><span>User country: {{ Prs.praddress.DistVal }}</span></p> -->
                    <p class="error-mess" v-if="errorObj.disErr.length > 0">{{ errorObj.disErr[0] }}</p>
                    
                </div>
                <div class="form-group">
                    <label id="uPradeshiya"><b>Select Pradeshiya Sabha | ප්‍රාදේශීය සභාව තෝරන්න:</b>
                    </label>
                    
                            <select class="form-control" @change="changesabha" name="pradeshiya" id="pradeshiya" v-model="rentObj.pra_sabha" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ps in P_Sabha" :key="ps.sb_code" :value="ps.sb_code">{{ ps.sb_name_en}}&nbsp;&nbsp;|&nbsp;&nbsp;{{ ps.sb_name_sin }} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.praErr.length > 0">{{ errorObj.praErr[0] }}</p>
                               
                </div>
                <div class="form-group">
                    <label id="umarket"><b>Select Market/Street/Place | මාර්කට්/වීදිය/ස්ථානය තෝරන්න:</b>
                    </label>
                    
                            <select class="form-control" @change="changemarket"  name="pradeshiya" id="pradeshiya" v-model="rentObj.market" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ms in marketstreets" :key="ms.ms_id" :value="ms.ms_id">{{ ms.place}} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.marketErr.length > 0">{{ errorObj.marketErr[0] }}</p>
                               
                </div>
                <div class="form-group">
                    <label id="rentShop"><b>Select Shop Number | කඩ කාමර අංකය තෝරන්න:</b>
                    </label>
                            <select class="form-control"  name="pradeshiya" id="pradeshiya" v-model="rentObj.shop" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="sno in shopnumbers" :key="sno.shop_no" :value="sno.shop_no">{{ sno.shop_no}} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.shopErr.length > 0">{{ errorObj.shopErr[0] }}</p>
                               
                </div>
                
                <div class="form-group">  
                            
                            <label for="month"><b>Year | වර්ෂය &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                     </label>
                                <select id="year" v-model="rentObj.year" class="form-control1">
                                <option value="" selected disabled>Choose Year</option>
                                <option v-for="year in years" :key="year" :value="year">{{ year }} </option>
                                </select>
                                
                    <p class="error-mess" v-if="errorObj.yearErr.length > 0">{{ errorObj.yearErr[0] }}</p>
                  </div>
                <div class="form-group">
                    
                
                    <label for="month"><b>Month | මාසය &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                    </label>
                    <select  @change="changemonth($selmonth)" name="month" id="month" v-model="rentObj.month" class="form-control1">
                                <option value="" selected disabled>Choose Month</option>
                                <option v-for="m in Months" :key="m.id" :value="m.id">{{ m.month}} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.monthErr.length > 0">{{ errorObj.monthErr[0] }}</p>
                 </div>
                 
                  <div class="form-group">
                    <label id="PayHead"><b>Select Shop Type | ඔබට අදාල කඩ කුලී වර්ගය තෝරන්න:</b>
                    </label>
               <br>
                    <input type="radio" v-model="rentObj.renttype" value="29" class="form-radio" @click="showshopRentdiv"><label id="PayHead">කඩ කාමර කුලී</label>
                    <input type="radio" v-model="rentObj.renttype" value="51" class="form-radio" @click="showMeatRentdiv"><label id="PayHead">මස් කඩ කාමර කුලී</label>
                    <p class="error-mess" v-if="errorObj.renttyperErr.length > 0">{{ errorObj.renttyperErr[0] }}</p>         
                </div>
                <div class="form-group hide" id="shopRentdiv" >
                    <label for="rentShop"><b>Shop Rent (Rs.) | මාසික කුලිය  (රු.)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                    </label>
                    <input type="number" name="rentShop" placeholder="your Shop Rent (Rs.)" id="rentShop" class="form-control1"
                        v-model="rentObj.shoprent"  />
                    <p class="error-mess" v-if="errorObj.rentErr.length > 0">{{ errorObj.rentErr[0] }}</p>
                    
                </div>
                <div class="form-group hide" id="MeatRentdiv">  
                            
                    <label for="rentShop"><b>Shop Rent (Rs.) | මාසික කුලිය  (රු.)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                    </label>
                    <input type="number" name="rentShop" placeholder="your Shop Rent (Rs.)" id="rentShop" class="form-control1"
                        v-model="rentObj.shoprent"  />
                                <!-- <select id="MeatRent" v-model="rentObj.shoprent" class="form-control1">
                                <option value="" selected disabled>Choose Amount</option>
                                <option v-for="ms in MeatRent" :key="ms.id" :value="ms.id">{{ ms.id }}</option>
                                </select> -->
                 
                  </div>
                <div class="form-group">
                    <label for="rdes"><b>Remarks  | ගෙවීම් විස්තරය:</b>
                    </label>
                    <textarea name="rentShop" placeholder="type your desctiption" id="rentShopdes" class="form-control"
                        v-model="rentObj.rentdes" ></textarea>
                   
                    <p class="error-mess" v-if="errorObj.rentdesErr.length > 0">{{ errorObj.rentdesErr[0] }}</p>
                    
                </div>

                <div class="form-group">
                    <input type="submit" value="Continue>>" class="btn" />
                    
                </div>
            </form>
        </div>
    </div>
</section>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import VueBasicAlert from 'vue-basic-alert'
export default {
   
    name: "Shoprent",
    el: '#vue-instance',
  
    data() {
        return {
            userNic:'',Nic:'',
            rentObj: { dis:"",pro:"",pra_sabha:"",shop:"",shoprent:"",rentdes:"",month:"",year:"",renttype:"",market:""},
            errorObj: { disErr: [],proErr: [], praErr: [],shopErr: [], rentErr: [], rentdesErr: [], monthErr: [], yearErr: [],renttyperErr:[] ,marketErr:[]},
            Districts: [],
            DistrictsData:undefined,
            sDist:undefined,
            SabhaData:undefined,
            Sabha:undefined,
            matchUser:undefined,
            Months: [{id:'1', month:'January'},
            {id:'2', month:'February'},
            {id:'3', month:'March'},
            {id:'4', month:'Appril'},
            {id:'5', month:'May'},
            {id:'6', month:'June'},
            {id:'7', month:'July'},
            {id:'8', month:'Aguest'},
            {id:'9', month:'September'},
            {id:'10', month:'October'},
            {id:'11', month:'November'},
            {id:'12', month:'December'}],

            MeatRent:[{id:'3000.00',des:'3000.00'},{id:'4000.00',des:'4000.00'},{id:'4500.00',des:'4500.00'}],

            P_Sabha: [],
            // distMatch: undefined,
            province: [],
            marketstreets:[],
            shopnumbers:[],

            selectedProVal: null,
            selectedDistVal: null,
        
            // prs_code:null,
            distr: {
                address: {
                    ProviceVal: null,
                    
                   
            }
             },
             Prs: {
                praddress: {
                    DistVal: null,
                   
            }
             }
        }
    },

        created() {
            // this.getSessionData();
            this.AllDistricts();
            this.Allprovince();
            this.AllprSabha();
            this.getSessionData();
        },
     
        computed : {
            ...mapState(["user"]),
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2020}, (value, index) => 2020 + index+1)
    }
    },
    methods: {
        ...mapMutations(["setUser"]),

        async MatchUser(nic) {
            let data = await axios.get('/customersdata/' + nic);
            this.matchUser = data.data;
        },
        async getSessionData(){
    
        const data=JSON.parse(sessionStorage.getItem('Userdata'));
        if(data){
            this.Nic=data.Unic
        }
            await this.MatchUser(this.Nic);
            this.setUser(this.matchUser);
        },
        async Allprovince() {
         this.province = (await axios.get('/provinces/' )).data;
         await axios.delete('/shoprentstemp/'+this.user.cus_nic );
         await axios.delete('/onlinepaymenttemp/'+this.user.cus_nic );
  
        },
        async AllDistricts() {
         this.Districts = (await axios.get('/districts/')).data;
         
        },
        async AllprSabha() {
         this.P_Sabha = (await axios.get('/Prasabha/' )).data;
        },
      
        async changeprovince() {
                let DistrictsData = (await  axios.get('/districts/' + this.rentObj.pro));
                this.Districts=DistrictsData.data
       },
      async changedist() {   
            let SabhaData = (await axios.get('/Prasabha/' + this.rentObj.dis));
            this.P_Sabha=SabhaData.data; 
       },
       async changesabha() {    
            let marketData = (await axios.get('/marketstreet/' + this.rentObj.pra_sabha));
            this.marketstreets=marketData.data; 
       },
       async changemarket() {    
            let shopno = (await axios.get('/marketstreetbyid/'+ this.rentObj.pra_sabha +'/'+ this.rentObj.market));
            this.shopnumbers=shopno.data; 
       },
       
       showshopRentdiv: function (){
            document.getElementById('shopRentdiv').style.display ='block';
            document.getElementById('MeatRentdiv').style.display = 'none';
        },
        showMeatRentdiv:function (){
            document.getElementById('MeatRentdiv').style.display = 'block';
            document.getElementById('shopRentdiv').style.display ='none';
        },

            resetCheckErr: function () {
            this.errorObj.disErr = [];
            this.errorObj.proErr= [];
            this.errorObj.praErr = [];
            this.errorObj.shopErr = [];
            this.errorObj.rentErr = [];
            this.errorObj.rentdesErr = [];
           
            
        },
        resetObjects: function(){
            this.rentObj.dis="";
            this.rentObj.pro="";
            this.rentObj.pra_sabha="";
            this.rentObj.shop="";
            this.rentObj.shop="";
            this.rentObj.rentdes="";
            this.rentObj.month=null
            this.rentObj.year=null;
            this.rentObj.shoprent="";
        },
        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
         checkForm: function () {
            this.resetCheckErr();

            // District validate
            if (!this.rentObj.dis) {
                this.errorObj.disErr.push("Choose the District ");
            }
       
            // Province validate
            if (!this.rentObj.pro) {
                this.errorObj.proErr.push("Choose the  Province ");
            }
          
            // Pradeshiya validate
            if (!this.rentObj.pra_sabha) {
                this.errorObj.praErr.push("Choose the Pradeshya Sabha");
            }
            // marketPlace validate
            if (!this.rentObj.market) {
                this.errorObj.marketErr.push("Choose the Market/Street/Place");
            }
            // shopnumbers validate
            if (!this.rentObj.shop) {
                this.errorObj.shopErr.push("Choose the Shop Number");
            }
           // Month Validate
            if (!this.rentObj.month) {
                this.errorObj.monthErr.push("Choose the Month");
            }

            // year Validate
            if (!this.rentObj.year) {
                this.errorObj.yearErr.push("Choose the Year");
            }
              // Type Validate
              if (!this.rentObj.renttype) {
                this.errorObj.renttyperErr.push("Select Shop Rent Type");
                
            }
           
           
            // Shop validate
            if (!this.rentObj.shop) {
                this.errorObj.shopErr.push("Shop is required");
            }
            else {
                if (!/[a-z0-9._%+-]+$/.test(this.rentObj.shop.replace(/\s/g, ""))) {
                    this.errorObj.shopErr.push('A Shop must be valid');
                }
            }
    


            // // Shop Rent validate
            if (!this.rentObj.shoprent) {
                this.errorObj.rentErr.push('Rent is required');
            }
            else {
              

                if (!/^[0-9.]+$/.test(this.rentObj.shoprent)) {
                    this.errorObj.rentErr.push('Rent must be valid numbers');
                }
            }
 
     // Rent Des validate
     if (this.rentObj.rentdes) {
            //     this.errorObj.rentdesErr.push("Description is required");
            // }
            // else {
                if (!/^[A-Za-z]+$/.test(this.rentObj.rentdes.replace(/\s/g, ""))) {
                    this.errorObj.rentdesErr.push('A Description contain letters');
                }
            }
       
        },
     
       async handleSubmit(e) {
           this.checkForm();
           
                if (!this.checkEmptyErr()) {
                    e.preventDefault();
                } else {
                 e.preventDefault();
               
                    if(!this.user){
                        this.$refs.alert.showAlert('error','Please Loging to Continue !')
                    }
                    else{
                     const data = {
                        // cus_nic:this.user.cus_nic,
                        sabha_code:this.rentObj.pra_sabha,
                        marketplace:this.rentObj.market,
                        shop_code: this.rentObj.shop,
                        remark: this.rentObj.rentdes,
                        price: this.rentObj.shoprent,
                        month:this.rentObj.month,
                        year:this.rentObj.year,
                        rentType:this.rentObj.renttype,
                        
                        
                    }
                    sessionStorage.setItem('rentDetails',JSON.stringify(data));
                    this.$router.push("/ShoprentInvoice");
                    // this.$router.push("/paymentform");
                //     this.resetObjects();
                // sessionStorage.removeItem("usernic");
                }
            }
        }
        
      
    },
    components: {
        VueBasicAlert
    }
}

</script>





<style scoped>

.register-container {
    padding: 1.5rem 9%;
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: none;
}

.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 40%;
    border: none;
    padding-bottom: 0.5rem;
}
.register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;

    width: 10%;
    border: none;
    margin-left: 10%;
}
.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #620a0a;

}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 30%;
    text-align: center;
    align-items: center;
    background: #620a0a;
    
}

.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 100%;

} 
.register-container .register-form-container form .hide {
    display: none;

} 
</style>
