import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/axios"
// import SmartTable from 'vuejs-smart-table'


createApp(App).use(router).use(store).mount('#app')

// import Vue from 'vue';
// import App from './App.vue';
// import PaymentForm from './pages/ShoprentInvoice.vue';

// Vue.config.productionTip = false;

// new Vue({
//   render: (h) => h(App),
// }).$mount('#app');

// npm install vue-router

