<template>
    <div class="thank-container">
    
        <h1>
            <p>
                <span>S</span>
                <span>o</span>
                <span>r</span>
                <span>r</span>
                <span>y</span>
            &nbsp;
                <span>Y</span>
                <span>o</span>
                <span>u</span>
                <span>r</span>
            </p>
            <p>
                <span>P</span>
                <span>a</span>
                <span>y</span>
                <span>m</span>
                <span>e</span>
                <span>n</span>
                <span>t</span>
            
            &nbsp;
                <span>i</span>
                <span>s</span>
                &nbsp;
                <span>C</span>
                <span>a</span>
                <span>n</span>
                <span>c</span>
                <span>e</span>
                <span>l</span>
                <span>l</span>
                <span>e</span>
                <span>d</span>
                

</p>
<br><br>
<p> <img src="../assets/images/sory.png" alt="" class="home-img" > </p>
         
        </h1>

        <div class="thank-letter">
          
            <p v-none>
                Please Try Again.
            </p>
            <router-link class="btn" to="/">Back to Home </router-link>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { mapState } from "vuex";
export default {
    name: 'Cancel',
    theCancelData:[],
    userNic:'',satatus:'',
    matchUser: undefined,
    
    created() {
   
            this.fetchdata();
        },
    computed : {
            ...mapState(["user"]),
          
    },
    methods: {
        ...mapMutations(["setUser"]),

        async getMatchUser(nic) {
            let data = await axios.get('/customersdata/' + nic);
            this.matchUser = data.data;
        },
        async fetchdata() {
                let url_string = window.location.href
                let url = new URL(url_string);
                this.userNic = url.searchParams.get("nic").split('?')[0];
                this.satatus = url.searchParams.get("nic").split('?')[1];
                await this.getMatchUser(this.userNic);
                this.setUser(this.matchUser);

                if(this.satatus==0){
                    await axios.delete('/shoprentstemp/'+this.userNic);
                    await axios.delete('/onlinepaymenttemp/'+this.userNic);
                }
            
         },    
        
    }
}
</script>
<script setup>
// enables v-focus in templates
const vNone = {
    mounted(el) {
        el.style.textTransform = "none";
    }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Luckiest+Guy");

.thank-container {
    padding: 2rem 9%;
    background: #fff;
    height: 70vh;
}


.thank-container h1 {
    width: 100%;
    height: 25px;
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 50px;
}

.thank-container h1  span {
    position: relative;
    color: #620a0a !important;
    top: 40px;
    display: inline-block;
    /* -webkit-animation: bounce 0.3s ease infinite alternate; */
    font-size: 25px;
    color: #fff;
    text-transform: none;
    /* text-shadow: 0 1px 0 #620a0a, 0 2px 0 #620a0a, 0 3px 0 #620a0a, 0 4px 0 #620a0a,
        0 5px 0 #620a0a, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
        0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4); */
}

/* .thank-container h1 p span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
}

.thank-container h1 p span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
}

.thank-container h1 p span:nth-child(4) {
    -webkit-animation-delay: 0.3s;
}

.thank-container h1 p span:nth-child(5) {
    -webkit-animation-delay: 0.4s;
} */

.thank-letter {
    position: relative;
    top: 180px;
    font-size: 20px;
    width: 40vw;
    margin: 0 auto;
    text-align: center;
}
.thank-letter .btn {
        background: #620a0a;
    }
.thank-letter a {
    margin-top: 20px;
}

/* @-webkit-keyframes bounce {
    100% {
        top: -20px;
        text-shadow: 0 1px 0 #620a0a, 0 2px 0 #620a0a, 0 3px 0 #620a0a, 0 4px 0 #620a0a,
            0 5px 0 #620a0a, 0 6px 0 #620a0a, 0 7px 0 #620a0a, 0 8px 0 #620a0a, 0 9px 0 #620a0a,
            0 50px 25px rgba(0, 0, 0, 0.2);
    }
} */

@media (max-width: 1024px) {
    .thank-container {
        padding: 0px;
    }

    .thank-container h1 {
        margin-top: 20px;
    }

    .thank-container h1 p span {
        font-size: 10px;
    }
   

    .thank-letter {
        font-size: 16px;
        width: 50vw;
        top: 160px;
    }
}

@media (max-width: 576px) {

    .thank-container h1 {
        margin-top: 50px;
    }

    .thank-container h1 p span {
        font-size: 10px;
    }

    .thank-letter {
        font-size: 14px;
        width: 90vw;
        top: 90px;
    }
}
</style>