<template>
    <div class="menu-section">
       
<!-- start -->
<vue-basic-alert :duration="400"  ref="alert" />
<div v-if="!user" >
            <div class="register-form-container">
                <!-- <form id="head"  novalidate autocomplete="off"> -->
                    <br> <br> <br> <br> <br>
                <div class="heading">
                <span>Please Loging First.....</span>
                
                <h3><router-link class="btn dark"  to="/login">Login</router-link></h3>
                </div>
          
            <h3><b></b></h3>
            </div>
            
                 <!-- </form> -->
            
        </div>
<div v-else class="shroff-container">
       <div class="shroff-form-container">
        
        <form id="ShopRentForm" @submit="handleSubmit" novalidate autocomplete="off">
         <div class="row">
            <div  class="col-sm-10 "></div>
            <div  class="col-sm-2 ">
                
                    <!-- <input type="button" value="Payment History" class="btn" /> -->
                    <!-- <router-link class="btn" @click="scrollToTop()" to="/assessmentpayhistory">Payment History</router-link>  -->
                    <router-link to="/assessmenttax" v-slot="{ href, navigate }">
                <div v-if="user" class="fas fa-arrow-left  icons" style="background: #dc380a;color: white;" @click="showLog">
                    <h5 @click="scrollToTop(), navigate,href">Payment</h5>
                </div>
                </router-link>
            </div>
           </div>
         
            <h4><b>Assessment Tax | වරිපනම් බදු</b></h4>
            <h4><b>Payment History of Assessment Tax | වරිපනම් බදු ගෙවීම් විස්තරය</b></h4>
            <div class="row">
           
            
           <div  class="col-sm-6 ">
            <!-- <h6>Customer Name : {{ user.cus_name }}</h6> -->
            </div>
            <div  class="col-sm-6 ">
                <!-- <input type="text" v-model="filterText" placeholder="Search..." class="form-control"> -->
            </div>
        </div>
           <div class="row">  
            <div  class="col-sm-12 ">
                
                
                <table class="ttable">
      <thead>
        <tr>
          <th>ID</th>
            <th>Invoice Number</th>
            <th>Ward Number</th>
            <th>Street</th>
            <th>Assessment Number</th>
            <th>Description</th>
            <th>Pay Amount</th>
          <th>Date</th>
        
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in paginatedData" :key="index">
            <td>{{ (currentPage - 1) * 20 + index + 1 }}</td>
            <td>{{ item.tax_invoice_num }}</td>
            <td>{{ item.tax_ward }}</td>
            <td>{{ item.tax_street }}</td>
            <td>{{ item.tax_no }}</td>
            <td>{{ item.tax_description }}</td>
          <td>{{ item.tax_price }}</td>
          <td>{{ formatDate(item.tax_cur_date) }}</td>
          <!-- Render other data cells -->
        </tr>
      </tbody>
    </table>

    <!-- <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
<span>  {{ currentPage }} / {{ totalPages }}</span>
<button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button> -->
<div class="pagination-container">
      <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
      <span>{{ currentPage }} / {{ totalPages }}</span>
      <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
    </div>
            </div>
            
           </div>
           <br>
           </form>
       </div>
</div>
<!-- end -->
</div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import VueBasicAlert from 'vue-basic-alert'



export default {
   name: "AssessmentTax",
   components: {
        VueBasicAlert
    },
   data() {
        return {
            userNic:'',Nic:'',
            matchUser:undefined,
            currentDate: new Date(),
            filterText:'',
            itemsPerPage: 20,
      currentPage: 1,
      data: [], // Your table data
        
        }
    },

        created() {
           
            this.getSessionData();
            this.fetchPaymentHistory();
        },
     
        computed : {
            ...mapState(["user"]),
            paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    
    // Check if this.data is an array before using slice method
    if (Array.isArray(this.data)) {
      return this.data.slice(startIndex, endIndex);
    } else {
      // Return empty array or handle loading state
      return [];
    }
    
   
  },
 
    
    totalPages() {
      return Math.ceil(this.data.length / this.itemsPerPage);
    },

    // filteredData() {
    //   const filterText = this.filterText.toLowerCase();
    //   return this.data.filter(item => {
    //     return item.name.toLowerCase().includes(filterText) || 
    //            item.email.toLowerCase().includes(filterText);
    //   });
    // }
       
},
     
    methods: {
      ...mapMutations(["setUser"]),
         async MatchUser(nic) {
               let data1 = await axios.get('/customersdata/' + nic);
               this.matchUser = data1.data;
         },
     
         formatDate(givedate) {
      const date = new Date(givedate);
      return date.toISOString().split('T')[0];
    },
         async getSessionData(){
         const data2=JSON.parse(sessionStorage.getItem('Userdata'));
         if(data2){
            this.Nic=data2.Unic
         }
            await this.MatchUser(this.Nic);
            this.setUser(this.matchUser);
           
         },
         async fetchPaymentHistory() {
    try {
      let response = await axios.get('/assessmenttax/' + this.user.cus_nic);
      this.data = response.data; // Assign retrieved data to payHistory
    } catch (error) {
      console.error('Error fetching payment history:', error);
    }
},
nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  // Prevent default form submission behavior
  event.preventDefault();
},
prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  // Prevent default form submission behavior
  event.preventDefault();
},
      
    },
   

};

</script>

<style scoped>
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}


hr {
   border-top: 3px solid #057835fa;
   width: 100%;
}



/* customer tab below */

::placeholder {
   color: white;
}

.menu-section {
   padding: 1rem 9%;
   min-height: 75vh;
   background-image: url("../assets/images/background3.jpg");
  background-repeat: no-repeat;
  background-size:cover;
  
  
   
}

.menu-section .menu-tabs {
   /* margin-bottom: 30px; */
   flex: 0 0 100%;
   max-width: 100%;
   text-align: center;
   background: #5e5c5cda;
   /* background-color: #27ae60; Income Topic */
}

.menu-section .menu-tabs .menu-tab-item {
   display: inline-block;
   cursor: pointer;
   padding: 5px 30px;
   border-radius: 30%;
   font-size: 20px;
   /* color: rgb(19, 18, 18); heading color */
   color: whitesmoke;
   font-weight: 500;
   text-transform: capitalize;
   transition: all 0.3s ease;
   margin: 0;
}


/* new */
.project-list>tbody>tr>td {
   padding: 12px 8px;
   font-size: small;
}

h3{
  text-align: center;

}


h4{
    text-align: center;
}

/* newly added from income heads */
.register-container {
   padding: 2rem 9%;
}

.register-container .register-form-container {
   background: #fff;
   /* width: 100% !important; */
}

.register-container .register-form-container form {
   /* position: relative; */
   /* left: 40%; */
   /* transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 1rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: bottom 1px solid; 
} 

.project-list>tbody>tr>td .input-box {
    /* margin: 0.7rem 0; */
   border-radius: 0.5rem;
   background: #e4e3e3;
   /* padding: 2rem 1.2rem; */
    font-size: 1.5rem; 
   color: #130f40;
   /* text-transform: none; */
   width: 100%;
   border: 1px solid; 
  
} 

.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: bottom 1px solid; 
} 

.register-container .register-form-container form label {
   font-size: 5rem;
   margin: 0;
   padding: 0;
   float:left;
}

.register-container .register-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
   display: block; 
   overflow: hidden; 
   
}

.register-container .register-form-container form .btn {
   margin: 1rem 0;
   width: 10%;
   text-align: center;
   background-color : #620a0a;
   height: 30px;
   font-size: 1rem; 
   color: #000;
}

.btn1 {
   margin: 1rem 0;
   width: 10%;
   text-align: center;
   background-color : #620a0a;
   height: 30px;
   font-size: 1rem; 
   color: #000;
   
}
.register-container .register-form-container form p {
   padding-top: 1rem;
   font-size: 1rem;
   color: #666;
   margin: 0;
   text-align: justify;
}
p {
   padding-top: 1rem;
   font-size: 0.5rem;
   color: #666;
   margin: 0;
   text-align: justify;
   text-transform: none;
}
.register-container .register-form-container form p a {
   color: #27ae60;
}

.register-container .register-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.register-container .register-form-container form .form-group {
   margin: 0;
   font-size: 5.5rem;
}

.register-container .register-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 0;
}
/* shroff form container */
.shroff-container {
   background-color: #ffffff09;
   height: auto;
   /* padding: 2rem 9%; */
   font-size: 16px;
   
}

.project-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}

.table-responsive {
   margin-top: 8vh;
   height: 500px;
}

.action-btn,
.cancel-btn,


.action-btn {
   background-color: #0da9ef;
   margin-right: 10px;
}

.cancel-btn,
.paid-btn {
   background-color: red;
}

.action-btn:hover {
   background-color: #27ae60;
}
.order-section form .row .input-box {
   width: 49%;
   padding: 1.8rem 0;
} 
/* newly added from income heads */
.shroff-container {
   padding: 2rem 9%;
}
/* ongoing */
.shroff-container .shroff-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.shroff-container .shroff-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
}

.shroff-container .shroff-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.shroff-container .shroff-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.shroff-container .shroff-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.shroff-container .shroff-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.shroff-container .shroff-form-container form .btn {
   /* margin: 1rem 0; */
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #620a0a;
    height: 3rem;
    color: #f4efef;
  
   
}
.shroff-container .shroff-form-container form .table {
   /* margin: 1rem 0; */
   width: 95%;
   text-align: center;
   font-size: small;
   background-color : #f7f7f7;
   /* padding-left: 5rem; */
    /* height: 3rem; */
   
}
.shroff-container .shroff-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.shroff-container .shroff-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: #666;
   margin: 0;
}
.shroff-container .shroff-form-container form  .error-mess {
   font-size: 1.2rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 0;
}

.shroff-container .shroff-form-container form p a {
   color: #27ae60;
}

.shroff-container .shroff-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.shroff-container .shroff-form-container form .form-group {
   margin: 0;
}

.shroff-container .shroff-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
.project-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   /* width: 22px; */
   border: 1px solid #CCC;
}

.table-responsive {
   margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
   width: 100px;
   height: 25px;
   color: white;
   text-transform: capitalize;
}

.action-btn {
   background-color: #0da9ef;
   margin-right: 10px;
}

.cancel-btn,
.paid-btn {
   background-color: red;
}

.action-btn:hover {
   background-color: #27ae60;
}
.icons  {
    height: 4.5rem;
    width: 5rem;
    line-height: 4.5rem;
    font-size: 2rem;
    background: #eb8e02;
    color: #f8f7fc;
    border-radius: .5rem;
    margin-left: .3rem;
    cursor: pointer;
    text-align: center;
        /* justify-content: space-between; */
}
h5{
 
 color: #08031c;
 text-align: center;
}
.ttable {
     
     width: 100%;
     border-width:1px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      border-collapse: collapse;
 }
 th  {
     text-align: center;
}
.ttable, th, td {
border: 1px solid;
text-align: center;
}
.pagination-container {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  margin-top: 10px; /* Adjust as needed */
}

</style>
