<template>
    <div class="login-container">
        <div class="login-form-container">
            <form id="loginForm" @submit="handleSubmit" novalidate autocomplete="off">
                 
                <div class="heading">
                    <h3 >Local Government Online Payment System</h3>
                    <h3 >LOGIN</h3>
                 </div>
                <div v-if="errors.length" class="error-box">
                    <ul>
                        <li v-for="error in errors" :key="error">{{ error }}</li>
                    </ul>
                </div>

                <div class="form-group">
                    <input type="text" id="uNIC" name="uNIC" class="form-control" placeholder="enter your nic | හැඳුනුම්පත් අංකය "
                        v-model="loginObj.nic" />
                </div>

                <div class="form-group">
                    <input type="password" id="uPass" name="uPass" class="form-control"
                        placeholder="enter your password | මුරපදය" v-model="loginObj.pass" />
                </div>

                <div class="form-group">
                    <input type="submit" value="login now | පිවිසෙන්න" class="btn">
                    <p>don't have an account? | නව ගිණුමක් සඳහා පිවිසෙන්න <router-link @click="scrollToTop()" to="/register">create one
                        </router-link>
                    </p>
                </div>
            </form>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import { mapMutations } from "vuex";
// import bcrypt from 'bcryptjs';
export default {
    name: 'Login',

    data() {
        return {
            loginObj: { nic: "", pass: "" },
            matchUser: undefined,
            // UName:'',
            errors: [],
        }
    }, 

    methods: {
        ...mapMutations(["setUser"]),

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        async getMatchUser(nic,pass) {
            // let data = await axios.get('/customerspass/' + nic+'/'+pass);
            // this.matchUser = data.data;
            try {
                let response = await axios.get('/customerspass/' + nic + '/' + pass);
                this.matchUser = response.data;
            } catch (error) {
                console.error("Error fetching user:", error);
                this.matchUser = null;
            }
        },
        addZero(num) {

            num = num.toString();
            return '19' + num.slice(0, 5) + '0' + num.slice(5, -1);
            // return '19' + num.slice(0, 5) + '0' + num.slice(5);
            },
            addZeroPadding: function() {
            // this.len= this.loginObj.nic.charAt(9).toUpperCase()
            // Check if the user input is a valid number
            // if (!isNaN(parseInt(this.loginObj.nic)) && this.loginObj.nic !== null && this.loginObj.nic.charAt(9).toUpperCase() === 'V') {
             if (!isNaN(parseInt(this.loginObj.nic)) && this.loginObj.nic !== null && (this.loginObj.nic.charAt(9).toUpperCase() === 'V' || this.loginObj.nic.charAt(9).toUpperCase() === 'X')) {
                
                this.paddedNum = this.addZero(this.loginObj.nic);
            
            }else{
                this.paddedNum=parseInt(this.loginObj.nic);
            }
            return this.paddedNum
            },


        async handleSubmit(e) {
            this.errors = [];

           
            if (!this.loginObj.nic) {
                this.errors.push("Entering a nic is required");
            }
            else {
              
                if ( this.loginObj.nic.length !== 12 &&
                            !(
                                this.loginObj.nic.charAt(9).toUpperCase() === 'V' ||
                                this.loginObj.nic.charAt(9).toLowerCase() === 'v' ||
                                this.loginObj.nic.charAt(9).toUpperCase() === 'X' ||
                                this.loginObj.nic.charAt(9).toLowerCase() === 'x'
                            )
                        ) {
                            this.errors.push('Please Enter Correct Nic Number');
                        }
            
            }

            if (!this.loginObj.pass) {
                this.errors.push('Password is required');
            }

            if (!this.errors.length == 0) {
                e.preventDefault();
            }
            else {
                e.preventDefault();
                let Nic12 = this.addZeroPadding();
                await this.getMatchUser(Nic12,this.loginObj.pass);
               
                 if(this.matchUser==='error'){
                    this.errors.push("Incorrect password!")
                 }else if(this.matchUser==='not'){
                    this.errors.push("User not Found!")
                 }else{
                    this.setUser(this.matchUser);
                        
                        const data={
                            Unic:this.matchUser.cus_nic,
                            Uname:this.matchUser.cus_name,
                        }
                        window.sessionStorage.setItem('Userdata',JSON.stringify(data));
                        this.$router.push("/");
                 
                        }

          
            }
        }

    }

}
</script>

<style scoped>
.login-container {
    padding: 2rem 9%;
    /* background-image: image-set(""); */
     background-image: url("../assets/images/loginbackground.jpg");
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* backface-visibility: inherit; */
  /* opacity: 0.3; */
  
}

.login-container .login-form-container {
    /* background-color: #fff; */
    min-height: 72vh;
}


.login-container .login-form-container form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 40rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: .5rem;
    animation: fadeUp .4s linear;
    background-color: #fefefec4;
}

.login-container .login-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    font-weight: bolder;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}.login-container .login-form-container form span {
    font-size: 15px;
    padding-left: 5px;
    padding-right: 40px;
} 

.login-container .login-form-container form .form-control {
    margin: .7rem 0;
    border-radius: .5rem;
    background: #fffdfd;
    padding: 2rem 1.2rem;
    font-size: 1.6rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: none;
}

.login-container .login-form-container form .btn {
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100%;
    background: #620a0a;
}

.login-container .login-form-container form p {
    padding-top: 1rem; 
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.login-container .login-form-container form p a {
    color: #620a0a;
}

.login-container .login-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.login-container .login-form-container form .error-box {
    background-color: #fff9fa;
    box-sizing: border-box;
    border: 2px solid rgba(255, 66, 79, .2);
    border-radius: 2px;
    font-size: 12px;
    margin-bottom: 20px;
}

.login-container .login-form-container form .error-box ul {
    list-style-type: none;
    margin: 0;
    padding: 10px 0px;
}

.login-container .login-form-container form .error-box ul li {
    padding-left: 10px;
    color: rgb(182, 0, 0);
}
</style>