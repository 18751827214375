/* eslint-disable */  
<template>
    <section>
        <div v-if="!user" >
            <div class="register-form-container">
                <!-- <form id="head"  novalidate autocomplete="off"> -->
                    <br> <br> <br> <br> <br>
                <div class="heading">
                <span>Please Loging First.....</span>
                
                <h3><router-link class="btn dark"  to="/login">Login</router-link></h3>
                </div>
          
            <h3><b></b></h3>
            </div>
            
                 <!-- </form> -->
            
        </div>
        
        <div v-else  class="register-container">
        <div class="register-form-container">
 
            
            <form @submit.prevent="submitForm" id="taxInvoince"  novalidate autocomplete="off">
                <pre>DateTime :{{ new Date().toLocaleString()}}</pre>
                <div class="heading">
                <span>Local Government Online Payment Management System</span>
                    <h3><b>Payment Reciept</b></h3>
                 </div>
                <div  class="table-responsive">
                    <h5 v-if="theSabhaData && theSabhaData[0]">{{ theSabhaData[0].sb_address }}</h5> 
                    <h5 v-if="theSabhaData && theSabhaData[0]">Tel:{{ theSabhaData[0].sb_contact}}, Fax:{{ theSabhaData[0].fax}}</h5> 
                  <h6>Invoice No : &nbsp;{{ InvouceNum }} </h6>
                <table class="table">
        
                    <tbody>
                        <tr>
                            <td class="bg-light-gray"><label for="Uname"><b>Your NIC | හැඳුනුම්පත් අංකය</b></label></td>
                            <td > <label for="Uname">{{ user.cus_nic }} </label></td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray"><label for="Uname"><b>Your Name | නම</b></label></td>
                            <td ><label for="Uname">{{ user.cus_name }}</label></td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray"><label for="year"><b>Ward Number | කොට්ඨාශ අංකය</b></label></td>
                            <td ><label for="Uname">{{ ward }}</label></td>  
                             
                        </tr> <tr>
                            <td class="bg-light-gray"><label for="year"><b>Name of Road,or Street | පාර හෝ වීදියේ නම</b></label></td>
                            <td ><label for="Uname">{{ street }}</label></td>  
                             
                        </tr>
                        <tr>
                            <td class="bg-light-gray"><label for="year"><b>Assessment Number | වරිපනම් අංකය</b></label></td>
                            <td ><label for="Uname">{{ taxNo }}</label></td>  
                             
                        </tr>
                        <tr>
                            <td class="bg-light-gray"> <label for="month"><b>Pay Amount | ගෙවන මුදල(රු.) </b></label></td>
                            <td ><label for="Uname">{{ taxtPayment }}</label> </td> 
                            
                        </tr>
                        <tr>
                            
                            <td class="bg-light-gray"> <label for="month"><b>Banking Service Charge | බැංකුව මගින් අය කරනු ලබන සේවා ගාස්තු  </b></label></td>
                            <td ><label for="Uname">{{ bankfee }}</label> </td> 
                            
                        </tr>
                        <tr>
                            <td class="bg-light-gray"> <label for="month"><b>Gand Total | ගෙවිය යුතු මුළු මුදල </b></label></td>
                            <td ><label for="Uname">{{ grandPayTotal}}</label> </td> 
                            
                        </tr>
                        <tr>
                            <td class="bg-light-gray"><label for="rdes"><b>Remarks  | ගෙවීම් විස්තරය</b> </label></td>
                            <td ><label for="Uname">{{ remark }}</label></td>
                        </tr>
                    </tbody>
                </table>
                </div>
              
            <br>
            <div class="table-responsive">  
                <h6><input @click="btnenable"  type="checkbox" id="checkid" v-model="checkVal"  >&nbsp;I certify that the above information is true and correct.| ඉහත තොරතුරු සත්‍ය හා නිවැරදි බවට සහතික කරමි. </h6>
                </div>
                <div class="form-group aling"  v-if="theSabhaData && theSabhaData[0] && theSabhaData[0].saba_ipg=='BOC'">
                    <button class="btn " id="payboc"  type="submit" :disabled="disableButton">Pay Now</button>
                </div>
            </form><br>
           
                <div v-html="form" id="formResponse" class="form-group aling" style="display: none;"></div><br>

                <!-- <div id="embed-target"> </div> -->
                <!-- <input class="form-group aling" type="button" value="Pay with Embedded Page" onclick="Checkout.showEmbeddedPage('#embed-target');" /> -->
        <!-- <input class="form-group aling" type="button" value="Pay with Payment Page" onclick="this.Checkout.showPaymentPage();" /> -->
        <!-- <input class="form-group aling" type="button" value="Pay with Payment Page" @click="showPaymentPage" /> -->
        </div>
    </div>
</section>
</template>


<script>

// import router from "@/router";
import axios from "axios";
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {
   
    name: "AssessmentTaxInvoice",
  
    data() {
        return {
                   
                        // Sname:'scsad',
                        grandPayTotal:0,bankfee:0,
                        cusName:'',Sname:'',
                        monthName:'',
                        checkVal:'',
                        sessionData:'',
                        InvouceNum:'',sabha_code:'',remark:'',taxtPayment:'',taxNo:'',
                        pay_head:0,
                        nextid:'',
                        Nic:'',
                        payamount:{},
                        theSabhaData:[],
                        matchUser:undefined,
                        matchPaymonth:[],
                        onlinPaydata:[],
                        shopDeID:[],
                        temp:[],
                        AllShopDtails:[],
                        postData: {
                            mid:'',
                            paytype:'',
                            amount:'',
                            referenceNumber: '',
                            email:'',
                            bill_to_forename:'',
                            bill_to_surname:'',
                            bill_to_address_line1:'',
                            // bill_to_address_line2:'',
                            bill_to_address_city:'',
                            bill_to_address_state:'',
                            bill_to_address_postal_code:'',
                            bill_to_address_country:'LK',
                        },
                        response: null,
                        error: null,
                        currentDate: new Date(),
                        // date:new Date(),
                       fine:0,totalshoprent:0,
                       Checkout: null,
                       pay_vote:'assessmenttax',
                       ward:'',
                       street:'',
                       formData: {
                            boc_sid : '',
                            boc_paytype: '',
                            boc_amount: '',
                            boc_grandamount:'',
                            boc_cus_nic: '',
                            boc_invoice: '',
                            boc_remarks:'',
                            country:'',
                            street :'',
                            street2 :'',
                            city :'',
                            state :'',
                            zip :'',

                        },
                        ipg:'',
                        disableButton:true,
                        // serverDate: null
        
                        
                       
        }
    },
 
        created() {
            this.getSessionData();
        //    this.LoadData();
           this.ShowMonthName();
        //    this.getshopdata();
        },
        computed : {
            ...mapState(["user"]),

             
         },
       
    methods: {
        ...mapMutations(["setUser"]),
    //     async getServerDate() {
    //   try {
    //     const response = await axios.get('/getserverdate'); // Assuming this endpoint returns the server date
    //     this.serverDate = response.data.date; // Adjust this according to your server's response
    //   } catch (error) {
    //     console.error('Error fetching server date:', error);
    //   }
    // },
    //     formattedDate: function() {
    //         this.currentDate=new Date();
    //         // this.currentDate=this.serverDate;
    //      const day = this.currentDate.getDate().toString().padStart(2, "0");
    //      const month = (this.currentDate.getMonth() + 1)
    //        .toString()
    //        .padStart(2, "0");
    //      const year = this.currentDate.getFullYear().toString();
    //      var defaultdate =`${year}-${month}-${day}`
    //     //  return `${year}-${month}-${day}`;
    //     return defaultdate
    //    },
    formattedDate() {
           
           let date = new Date()
           const year = date.getFullYear();
     const month = String(date.getMonth() + 1).padStart(2, '0');
     const day = String(date.getDate()).padStart(2, '0');
   const fdate=`${year}-${month}-${day}`;
   const options = { timeZone: 'Asia/Colombo', year: 'numeric', month: '2-digit', day: '2-digit' };
   return fdate.toString('en-US', options);
   //   return `${year}-${month}-${day}`;
         },

        async MatchUser(nic) {
            let data = await axios.get('/customersdata/' + nic);
            this.matchUser = data.data;
        },
       
  

  
       async getSessionData(){
        
        const data=JSON.parse(sessionStorage.getItem('taxDetails'));
            if(data){
                // this.cus_nic=data.cus_nic
                this.sabha_code=data.sabha_code
                this.remark=data.remark
                this.ward=data.ward
                this.street=data.street
                this.taxNo=data.taxNo
                this.taxtPayment=data.taxtPayment
                this.InvouceNum=data.invoiceNum
                // this.payamount=data.payamount

            }
            this.sessionData=data;
            // this.payamount=JSON.parse(sessionStorage.getItem('payamounts'));
            const nicdata=JSON.parse(sessionStorage.getItem('Userdata'));
            if(nicdata){
                this.Nic=nicdata.Unic
            }
            await this.MatchUser(this.Nic);
            this.setUser(this.matchUser);
            // let lastGennum=null
        if(this.sessionData){

            this.theSabhaData = (await axios.get('/PraSabhadata/' + this.sabha_code)).data
            this.Sname='tax'
            this.ipg= this.theSabhaData[0].saba_ipg

            // this.AllShopDtails = (await axios.get('/shopdata/'+this.shopDeID )).data;
            let datau = await axios.get('/customersdata/' + this.user.cus_nic);
            this.matchUser = datau.data;
            this.cusName=this.matchUser.cus_name;
            // this.onlinPaydata= (await axios.get('/genaratenum/')).data;
            // lastGennum=parseInt(this.onlinPaydata[0].gen_num);
           
            // this.InvouceNum= (this.sabha_code.concat(this.currentDate.getMonth(),this.currentDate.getFullYear(),lastGennum));
            // this.InvouceNum='PRS571120232'
            // this.nextid= lastGennum+1;

            //         const genInvNum = {
            //             gen_num:this.nextid,
            //        }
            // await axios.put('/genaratenum/',genInvNum);
           
           if(this.user){
          
            if(this.ipg=='BOC'){
                this.bankfee=(parseFloat(this.taxtPayment)*((100/(100-1))-1)).toFixed(2)

            }else{
                 this.bankfee=(parseFloat(this.taxtPayment)*0.01).toFixed(2)
            }

                this.grandPayTotal=(parseFloat(this.bankfee)+parseFloat(this.taxtPayment)).toFixed(2)
            const datatax = {
                       tax_cus_nic:this.user.cus_nic,
                       tax_invoice_num :this.InvouceNum,
                       tax_sabha_code:this.sabha_code,
                       tax_ward :this.ward,
                       tax_street :this.street,
                       tax_no: this.taxNo,
                       tax_description: this.remark,
                       tax_price:this.taxtPayment
                   }
                   await axios.post("/assessmenttaxtemp/",datatax);
                
           const onlineData = {
                   online_cus_nic:this.user.cus_nic,
                   online_sabha_code:this.sabha_code,
                   online_invoice_num:this.InvouceNum,
                   online_pay_amount:this.taxtPayment,
                   online_pay_month:0,
                   online_pay_year:0,
                   online_pay_des:this.remark,
                   pay_type:"assessmenttax",
                   vote:this.pay_vote,
                   online_pay_date:this.formattedDate()
                   
               // ... other key-value pairs
                   };
                   await axios.post("/onlinepaymenttemp/",onlineData);

           }
           this.postData.mid=this.sabha_code,
           this.postData.paytype='tax',
            this.postData.amount=this.grandPayTotal,
            this.postData.referenceNumber=this.InvouceNum,
            this.postData.email=this.user.cus_email,
            this.postData.bill_to_forename=this.user.cus_nic,
            this.postData.bill_to_surname=this.user.cus_name,
            this.postData.bill_to_address_line1=this.user.cus_address,
            // this.postData.bill_to_address_line2=this.user.cus_address,
            this.postData.bill_to_address_city=this.Sname,
            this.postData.bill_to_address_country='LK',
            
      axios
    //   .post('/processpaymentboc', this.postData, {
        .post('/process-payment', this.postData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          this.response = response.data;
        
        //   alert("response");
        //   alert(response.data);
          document.getElementById('formResponse').innerHTML=response.data;
          document.getElementById('submit').style.display="none";
        })
        .catch((error) => {
          this.error = error;
        }); 
    }else{
        // this.$router.push("/shoprentexist");
        this.$router.push('/assessmenttax');
    }

        },
///////////////////////////BOC GATE WAY//////////////////////
        submitForm() {
            this.formData.boc_sid =this.sabha_code
                            this.formData.boc_paytype='tax'
                            this.formData.boc_amount=this.Totalpayamount
                            this.formData.boc_grandamount=this.grandPayTotal
                            this.formData.boc_cus_nic=this.user.cus_nic
                            this.formData.boc_invoice=this.InvouceNum
                            this.formData.boc_remarks=this.remark
                            this.formData.country='LKA'
                            this.formData.street ='25466'
                            this.formData.street2 =this.Sname
                            this.formData.city ='25466'
                            this.formData.state ='25466'
                            this.formData.zip ='25466'   

        // console.log("Response");
        //   axios.post('http://localhost:3000/api/bocpay/', this.formData)
      axios.post('https://elgservices.lk/api/bocpay/', this.formData)
        .then(response => {
        // window.location.href = "https://test-bankofceylon.mtf.gateway.mastercard.com/checkout/pay/"+response.data.sessionid+"?checkoutVersion=1.0.0"   // test url
        window.location.href = "https://bankofceylon.gateway.mastercard.com/checkout/pay/"+response.data.sessionid+"?checkoutVersion=1.0.0" // production url

          console.log(response.data.sessionid);
        })
        .catch(error => {
          console.error(error);
        });
    },   
      

        
        // scrollToTop() {
        //     window.scrollTo(0, 0);
        // },
     
        
        getMonthName:function (monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber);

            return date.toLocaleString('en-US', { month: 'long' });
            },

        ShowMonthName(){
            this.monthName=this.getMonthName(this.month);
        },
        btnenable(){
            if(this.checkVal==true){
                document.getElementById('formResponse').style.display='none'
                this.disableButton = true;
                // let submitButton = document.getElementById('payboc');
                // submitButton.disabled = true;
                // document.getElementById('payboc').disabled=true


            }else{
                document.getElementById('formResponse').style.display='block'
                // document.getElementById('payboc').disabled=false
                this.disableButton = !this.disableButton;
            }
        },  

       
        
    
}
}
</script>





<style scoped>
.register-container {
    padding: 1.5rem 9%;
    min-height: 80vh;
   background-image: url("../assets/images/regbacground.jpg");
  background-repeat: no-repeat;
  background-size:cover;
}

.register-container .register-form-container {
    background: #ffffffe9;

}

/* .register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
} */

.register-container .register-form-container form h3 {
    /* padding-bottom: 0.1rem; */
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form h4 {
    /* padding-bottom: 0.2rem; */
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: left;
    
}

.register-container .register-form-container form h5 {
    padding-bottom: 0.2rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: left;
    
}

.register-container .register-form-container  pre {
    /* padding-bottom: 0.2rem; */
    font-size: 1.0rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: right;
    padding-top: 1%;
    padding-right: 2%;
    
}
.register-container .register-form-container form h6 {
    /* padding-bottom: 0.2rem; */
    font-size: 1.25rem;
    /* text-transform: uppercase; */
    color: #130f40;
    /* margin: 0; */
    text-align: left;
    
}
.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    /* background: #f7f7f7; */
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: none;
   
}

.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0.6rem;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #620a0a;
}


.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 20%;
    text-align: center;
    align-items: center;
    background: #620a0a;
    
}


.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 100%;

}

 .heading{
    padding: 0;
 }
/* Table */
.bg-light-gray {
    background-color: #f7f7f7;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 0px solid #dee2e6;
    font-size: 0.5rem;
}
label{

    font-size: 0.2rem;
}

.table-responsive{
    padding-left: 3.5rem;
    width: 95%;
  /* align:center; */
}

.table-responsive1{
    padding-left: 8rem;
    /* width: 95%; */
  /* align:center; */
}
.table1{
  align-items: center;
    width: 90%;
    font-size:small;
   
  /* align:center; */
}
.table1 td
 {
    padding: .25rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    width: auto;
    text-align: center;
} 
.table td,
.table th {
    padding: .25rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    width: 30%;
} 
.aling{
    align-items: center;
    text-align: center;
align-content: center;
/* padding-right: 15%; */
}  
</style>
