<template>
    <div class="menu-section">
       
<!-- start -->
<vue-basic-alert :duration="400"  ref="alert" />
<div v-if="!user" >
            <div class="register-form-container">
                <!-- <form id="head"  novalidate autocomplete="off"> -->
                    <br> <br> <br> <br> <br>
                <div class="heading">
                <span>Please Loging First.....</span>
                
                <h3><router-link class="btn dark" @click="scrollToTop()" to="/login">Login</router-link></h3>
                </div>
          
            <h3><b></b></h3>
            </div>
            
                 <!-- </form> -->
            
        </div>
<div v-else class="shroff-container">
       <div class="shroff-form-container">
        
        <form id="ShopRentForm" @submit="handleSubmit" novalidate autocomplete="off">
         <div class="row">
            <div  class="col-sm-10 "></div>
            <div  class="col-sm-2 ">
                    <!-- <input type="button" value="Payment History" class="btn" /> -->
                    <!-- <router-link class="btn" @click="scrollToTop()" to="/assessmentpayhistory">Payment History</router-link>  -->
                    <router-link to="/assessmentpayhistory" v-slot="{ href, navigate }">
                <div v-if="user" class="fas fa-history  icons" style="background: #dc380a;color: white;" @click="showLog">
                    <h5 @click="scrollToTop(), navigate,href">Payment History</h5>
                </div>
                </router-link>
            </div>
           </div>
         
            <span><b>Assessment Tax | වරිපනම් බදු</b></span>
         
           <br>
          <div class="row">
             <h4>For payment of assessment tax related to your assets, select the council where your assets belong</h4>
               <h4> ඔබගේ වත්කම් වලට අදාල වරිපනම් බදු ගෙවීම සඳහා, ඔබගේ වත්කම් අයත් සභාව තෝරන්න</h4>
           <div class="col-sm-12">
                    <label id="uProvince"><b>Select Province | පළාත තෝරන්න:</b>
                    </label>
                       
                           <select  @change="changeprovince"   v-model="rentObj.pro"  name="province" id="province" class="form-control">
                                <option value="" selected disabled>Choose</option>
                                <option v-for="f in province" v-bind:key="f.pro_id" v-bind:value="f.pro_code">{{ f.pro_name }} </option>
                                
                                
                            </select>
                
                    <p class="error-mess" v-if="errorObj.proErr.length > 0">{{ errorObj.proErr[0] }}</p>

                    
                </div>
            </div> 
            <div class="row">
                <div class="col-sm-12">
                    <label id="uDistrict"><b>Select District | දිස්ත්‍රික්කය තෝරන්න:</b>
                    </label>
                    
                            <select class="form-control" @change="changedist" name="district" id="district" v-model="rentObj.dis" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ds in Districts" :key="ds.dist_code" :value="ds.dist_code">{{ ds.dist_name }} </option>
                            </select>
                                
                          
                                <!-- <p><span>User country: {{ Prs.praddress.DistVal }}</span></p> -->
                    <p class="error-mess" v-if="errorObj.disErr.length > 0">{{ errorObj.disErr[0] }}</p>
                    
                </div>

            </div>
            <div class="row">
                <div class="col-sm-12">
                    <label id="uPradeshiya"><b>Select Pradeshiya Sabha | ප්‍රාදේශීය සභාව තෝරන්න:</b>
                    </label>
                    
                            <select @change=AllShopData class="form-control" name="pradeshiya" id="pradeshiya" v-model="rentObj.pra_sabha" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ps in p_sabha" :key="ps.sb_code" :value="ps.sb_code">{{ ps.sb_name_en}}&nbsp;&nbsp;|&nbsp;&nbsp;{{ ps.sb_name_sin }} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.praErr.length > 0">{{ errorObj.praErr[0] }}</p>
                               
                </div>
                
            </div>
            
            <div id="pay" style="display: none;">
               <p>* Your payment receipt will be received within two weeks at the address where your property belongs to.
                  Complete the following information against the Form K you have received.
               </p>
               <p>* ඔබගේ ගෙවීමට අදාල ලදුපත සති දෙකක් ඇතුලත ඔබගේ වරිපනම් බදු ගෙවීමට අදාල වත්කම අයත් ලිපිනයට ලැබෙනු ඇත.
                  ඔබට ලැබී ඇති K පෝරමයට අදාලව පහත තොරතුරු සම්පූර්ණ කරන්න.
                                </p>
                                <br>
            <div class="row">
            <div class="col-sm-1">  </div>
                   <div class="col-sm-5">  
                            
                            <label for="ward"><b>Ward Number | කොට්ඨාශ අංකය &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                     </label>
                     <input type="text" name="wardnum"  id="wardnum" class="form-control" v-model="rentObj.ward">
                                
                    <p class="error-mess" v-if="errorObj.wardErr.length > 0">{{ errorObj.wardErr[0] }}</p>
                  </div>
                  <div class="col-sm-5">  
                            
                            <label for="street"><b>Name of Road,or Street | පාර හෝ වීදියේ නම &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                     </label>
                     <input type="text" name="street"  id="street" class="form-control" v-model="rentObj.street">
                                
                    <p class="error-mess" v-if="errorObj.streetErr.length > 0">{{ errorObj.streetErr[0] }}</p>
                  </div>

            </div>
           <div class="row">
            <div class="col-sm-1">  </div>
            <div class="col-sm-5">  
                            
                            <label for="month"><b>Assessment Number | වරිපනම් අංකය &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                     </label>
                     <input type="text" name="payamount"  id="payamount" class="form-control" v-model="rentObj.taxNum">
                                
                    <p class="error-mess" v-if="errorObj.taxNumErr.length > 0">{{ errorObj.taxNumErr[0] }}</p>
                  </div>  
                <div class="col-sm-5">
                    
                
                    <label for="month"><b>Pay Amount | ගෙවන මුදල &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                    </label>
                    <input type="tel" name="payamount"  id="payamount" class="form-control" v-model="rentObj.Payment">
                            <p class="error-mess" v-if="errorObj.PaymentErr.length > 0">{{ errorObj.PaymentErr[0] }}</p>
                 </div>
                

                </div>
               
                <div class="row">
                  <div  class="col-sm-1"></div>
                  <div  class="col-sm-10">
                    <label for="rdes"><b>Remarks | ගෙවීම් විස්තරය:</b>
                    </label>
                    <textarea name="rentShop" placeholder="type your desctiption" id="rentShopdes" class="form-control"
                        v-model="rentObj.paydes" ></textarea>
                   
                    <p class="error-mess" v-if="errorObj.paydesErr.length > 0">{{ errorObj.paydesErr[0] }}</p>
                    
                   </div>
                  </div>
                  <br>   
           <div class="row">
            <div  class="col-sm-12">
   
                    <input type="submit" value="Continue>>" class="btn" />
                    
            </div>
           </div>
        </div>

        <!-- <div id="pay2" style="display: none;">
         <div  class="col-sm-12">
                   <p >ඔබගේ තේරීම නිවැරදි නොවේ....ඔබගේ කඩ කාමර කුලිය ගෙවීම සඳහා, ඔබගේ කඩ කාමරය අයත් සභාව නිවැරදිව තෝරන්න</p>
                    
            </div>
        </div> -->
           <br>
           </form>
       </div>
</div>
<!-- end -->
</div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import VueBasicAlert from 'vue-basic-alert'
export default {
   name: "AssessmentTax",

   data() {
        return {
            userNic:'',Nic:'',
            rentObj: { pro:"",dis:"",pra_sabha:"",paydes:"",taxNum:"",Payment:"",street:"",ward:""},
            errorObj: { disErr: [],proErr: [], praErr: [], paydesErr: [], taxNumErr: [], streetErr: [], wardErr: [], PaymentErr: []},
            p_sabha:[],
            SabhaData:undefined,
            Sabha:undefined,
            matchUser:undefined,
            matchPaymonth:[],
           

         //   checkVal:[],payamount:{},
            AllShopDtails: [],
           
            Districts: [],
            DistrictsData:undefined,
            sDist:undefined,
           
            // distMatch: undefined,
            province: [],onlinPaydata:[],
            
            // distMatch: undefined,
            len:0,len2:0,
            currentDate: new Date(),
            lastGennum:null,
            nextid:'',

           
        
        }
    },

        created() {
           
            this.AllDistricts();
            this.Allprovince();
            this.AllprSabha();
            this.getSessionData();
            // this.loard();
            // this.AllShopData();
        },
     
        computed : {
            ...mapState(["user"]),
         
            
        },
     
    methods: {
      ...mapMutations(["setUser"]),
         async MatchUser(nic) {
               let data = await axios.get('/customersdata/' + nic);
               this.matchUser = data.data;
         },
     
        
         async getSessionData(){
         const data=JSON.parse(sessionStorage.getItem('Userdata'));
         if(data){
            this.Nic=data.Unic
         }
            await this.MatchUser(this.Nic);
            this.setUser(this.matchUser);
            await axios.delete('/deletetasstempbynic/'+this.user.cus_nic );
            await axios.delete('/onlinepaymenttemp/'+this.user.cus_nic );
            // window.sessionStorage.removeItem('rentDetails');
            // window.sessionStorage.removeItem('shopDataid');

            
         },

         // isChecked(shopId) {
         //    console.log('checkedShops:', this.checkVal);
         //     console.log('shopId:', shopId);
         //  return this.checkVal.includes(shopId);
         //  },
   

        async Allprovince() {
           
         this.province = (await axios.get('/provinces/' )).data;
        
        },
        async AllDistricts() {
         this.Districts = (await axios.get('/districts/')).data;
         
        },
        async AllprSabha() {
         this.p_sabha = (await axios.get('/Prasabha/' )).data;
  
        },
   
        async changeprovince() {

                let DistrictsData = (await  axios.get('/districts/' +this.rentObj.pro));
                this.Districts=DistrictsData.data
            
       },
      async changedist() {
            
            let SabhaData = (await axios.get('/Prasabha/' + this.rentObj.dis));
            this.p_sabha=SabhaData.data;
          
            
       },
        async AllShopData() {
         document.getElementById('pay').style.display ='block';
           
          
        },
         
        resetCheckErr: function () {
            this.errorObj.disErr = [];
            this.errorObj.proErr= [];
            this.errorObj.praErr = [];
            this.errorObj.taxNumErr = [];
            this.errorObj.PaymentErr = [];
            this.errorObj.paydesErr = [];
            this.errorObj.wardErr = [];
            this.errorObj.streetErr = [];
           
           
            
        },
        resetObjects: function(){
            this.rentObj.dis="";
            this.rentObj.pro="";
            this.rentObj.pra_sabha="";
            this.rentObj.paydes="";
            this.rentObj.taxNum="";
            this.rentObj.Payment="";
            this.rentObj.ward="";
            this.rentObj.street="";
         
        },
        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
         checkForm: function () {
            this.resetCheckErr();

        
            // pay amount Validate
            if (!this.rentObj.Payment) {
                this.errorObj.PaymentErr.push("Please Enter Pay Amount ");
            }
            else{
                    if(!/[0-9]/.test(this.rentObj.Payment)) {
                    this.errorObj.PaymentErr.push('Pay Amount should be numbers');
                    }
                 }
            // Month Validate
            if (!this.rentObj.taxNum) {
                this.errorObj.taxNumErr.push("Please Enter Your Assessment Tax Number");
            }
            if (!this.rentObj.ward) {
                this.errorObj.wardErr.push("Please Enter ward no");
            }
            if (!this.rentObj.street) {
                this.errorObj.streetErr.push("Please Enter Street");
            }
 
          
   //   // Rent Des validate
   //   if (!this.rentObj.rentdes) {
   //              this.errorObj.rentdesErr.push("Description is required");
            
   //          }
       
        },

        async handleSubmit(e) {
           this.checkForm();
           
                if (!this.checkEmptyErr()) {
                    e.preventDefault();
                } else {
                 e.preventDefault();
                
                    if(!this.user){
                        this.$refs.alert.showAlert('error','Please Loging to Continue !')
                    }
                    else{
                     // if(!this.checkVal && this.payamount){
                        // this.len=this.checkVal.length;
                        // this.len2=Object.keys(this.payamount).length;

                     //  if(this.len>0 && this.len2>0 ){  
                     // sessionStorage.setItem('shopDataid',JSON.stringify(this.checkVal));
                     this.onlinPaydata= (await axios.get('/genarateinvonline/'+this.rentObj.pra_sabha)).data;
                     this.lastGennum=parseInt(this.onlinPaydata[0].gen_num);
           
            let InvouceNum= (this.rentObj.pra_sabha.concat(this.currentDate.getMonth(),this.currentDate.getFullYear(),this.lastGennum));
            this.nextid= this.lastGennum+1;

               const genInvNum = {
                  gen_num:this.nextid,
               }
               await axios.put('/updateinvonline/'+this.rentObj.pra_sabha,genInvNum);

            let paymenttax=parseFloat(this.rentObj.Payment).toFixed(2)
                     const data = {
                        sabha_code:this.rentObj.pra_sabha,
                        remark: this.rentObj.paydes,
                        ward:this.rentObj.ward,
                        street:this.rentObj.street,
                        taxNo:this.rentObj.taxNum,
                        taxtPayment:paymenttax,
                        invoiceNum:InvouceNum,
                        
                        // payamount:this.rentObj.payamount,
                        }

                        // console.log("test sss");
                    sessionStorage.setItem('taxDetails',JSON.stringify(data));
                     //  sessionStorage.setItem('payamounts',JSON.stringify(this.payamount));

                           // console.log("test sss");


                           // sessionStorage.setItem('sessionid',"hjhj");
                           // sessionStorage.setItem('successid',"ghghg");
                           
                                 this.$router.push("/assessmenttaxinvoice");
                     // }
                     // else{
                     //    this.$refs.alert.showAlert('error',"Please Enter Pay Amount and  Select to Pay ")
                     // }
                //     this.resetObjects();
                // sessionStorage.removeItem("usernic");
                }
            }
        }
        
      
    },
    components: {
        VueBasicAlert
    }

};

</script>

<style scoped>
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}


hr {
   border-top: 3px solid #057835fa;
   width: 100%;
}



/* customer tab below */

::placeholder {
   color: white;
}

.menu-section {
   padding: 1rem 9%;
   min-height: 75vh;
   background-image: url("../assets/images/background3.jpg");
  background-repeat: no-repeat;
  background-size:cover;
  
  
   
}

.menu-section .menu-tabs {
   /* margin-bottom: 30px; */
   flex: 0 0 100%;
   max-width: 100%;
   text-align: center;
   background: #5e5c5cda;
   /* background-color: #27ae60; Income Topic */
}

.menu-section .menu-tabs .menu-tab-item {
   display: inline-block;
   cursor: pointer;
   padding: 5px 30px;
   border-radius: 30%;
   font-size: 20px;
   /* color: rgb(19, 18, 18); heading color */
   color: whitesmoke;
   font-weight: 500;
   text-transform: capitalize;
   transition: all 0.3s ease;
   margin: 0;
}


/* new */
.project-list>tbody>tr>td {
   padding: 12px 8px;
   font-size: small;
}



h4{
   padding-left: 2%;
}

/* newly added from income heads */
.register-container {
   padding: 2rem 9%;
}

.register-container .register-form-container {
   background: #fff;
   /* width: 100% !important; */
}

.register-container .register-form-container form {
   /* position: relative; */
   /* left: 40%; */
   /* transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 1rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: bottom 1px solid; 
} 

.project-list>tbody>tr>td .input-box {
    /* margin: 0.7rem 0; */
   border-radius: 0.5rem;
   background: #e4e3e3;
   /* padding: 2rem 1.2rem; */
    font-size: 1.5rem; 
   color: #130f40;
   /* text-transform: none; */
   width: 100%;
   border: 1px solid; 
  
} 

.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: bottom 1px solid; 
} 

.register-container .register-form-container form label {
   font-size: 5rem;
   margin: 0;
   padding: 0;
   float:left;
}

.register-container .register-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
   display: block; 
   overflow: hidden; 
   
}

.register-container .register-form-container form .btn {
   margin: 1rem 0;
   width: 10%;
   text-align: center;
   background-color : #620a0a;
   height: 30px;
   font-size: 1rem; 
   color: #000;
}

.btn1 {
   margin: 1rem 0;
   width: 10%;
   text-align: center;
   background-color : #620a0a;
   height: 30px;
   font-size: 1rem; 
   color: #000;
   
}
.register-container .register-form-container form p {
   padding-top: 1rem;
   font-size: 1rem;
   color: #666;
   margin: 0;
   text-align: justify;
}
p {
   padding-top: 1rem;
   font-size: 0.5rem;
   color: #666;
   margin: 0;
   text-align: justify;
   text-transform: none;
}
.register-container .register-form-container form p a {
   color: #27ae60;
}

.register-container .register-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.register-container .register-form-container form .form-group {
   margin: 0;
   font-size: 5.5rem;
}

.register-container .register-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 0;
}
/* shroff form container */
.shroff-container {
   background-color: #ffffff09;
   height: auto;
   /* padding: 2rem 9%; */
   font-size: 16px;
   
}

.project-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}

.table-responsive {
   margin-top: 8vh;
   height: 500px;
}

.action-btn,
.cancel-btn,


.action-btn {
   background-color: #0da9ef;
   margin-right: 10px;
}

.cancel-btn,
.paid-btn {
   background-color: red;
}

.action-btn:hover {
   background-color: #27ae60;
}
.order-section form .row .input-box {
   width: 49%;
   padding: 1.8rem 0;
} 
/* newly added from income heads */
.shroff-container {
   padding: 2rem 9%;
}
/* ongoing */
.shroff-container .shroff-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.shroff-container .shroff-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
}

.shroff-container .shroff-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.shroff-container .shroff-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.shroff-container .shroff-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.shroff-container .shroff-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.shroff-container .shroff-form-container form .btn {
   /* margin: 1rem 0; */
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #620a0a;
    height: 3rem;
    color: #f4efef;
  
   
}
.shroff-container .shroff-form-container form .table {
   /* margin: 1rem 0; */
   width: 95%;
   text-align: center;
   font-size: small;
   background-color : #f7f7f7;
   /* padding-left: 5rem; */
    /* height: 3rem; */
   
}
.shroff-container .shroff-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.shroff-container .shroff-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: #666;
   margin: 0;
}
.shroff-container .shroff-form-container form  .error-mess {
   font-size: 1.2rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 0;
}

.shroff-container .shroff-form-container form p a {
   color: #27ae60;
}

.shroff-container .shroff-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.shroff-container .shroff-form-container form .form-group {
   margin: 0;
}

.shroff-container .shroff-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
.project-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   /* width: 22px; */
   border: 1px solid #CCC;
}

.table-responsive {
   margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
   width: 100px;
   height: 25px;
   color: white;
   text-transform: capitalize;
}

.action-btn {
   background-color: #0da9ef;
   margin-right: 10px;
}

.cancel-btn,
.paid-btn {
   background-color: red;
}

.action-btn:hover {
   background-color: #27ae60;
}
.icons  {
    height: 4.5rem;
    width: 5rem;
    line-height: 4.5rem;
    font-size: 2rem;
    background: #eb8e02;
    color: #f8f7fc;
    border-radius: .5rem;
    margin-left: .3rem;
    cursor: pointer;
    text-align: center;
        /* justify-content: space-between; */
}
h5{
 
 color: #08031c;
 text-align: center;
}
</style>
