<template>
    <vue-basic-alert :duration="400"  ref="alert" />
    <div class="home">
        <div class="home-main">
        
            <div class="content">
              
            </div>
          
        </div>
       
    <br><br>

        <div class="home-banner">
            <div class="grid-banner row">
            <h3><b>We are constantly trying to provide services for your convenience. In order to provide you
                 with the best service standards, elg hereby brings your own Local Authority much closer to you now!
                 24x7 Online Payment Facility</b>
                </h3>
              <!-- <p v-if="userNic">{{ userNic }}</p> -->
            </div>
            <br> <br>
            <div class="grid-banner row">
                <div class="grid col-md-4">
                    <!-- <img src="../assets/images/dis-1.jpg" alt=""> -->
                    <div>
                        <!-- <span>Shop Rent |කඩ කුලී</span> -->
                        <h3><b><router-link @click="scrollToTop()" to="/onlinebookinghistory" >Online Seriveces </router-link><br>මාර්ගගතව ඔබට ලබාගත හැකි සේවාවන්</b></h3>
                        
                        <h4> <router-link @click="scrollToTop()" to="/onlinebookinghistory" class="btn">View Your Online Requests</router-link></h4>
                        <h4> <router-link @click="scrollToTop()" to="" class="btn">Baco | බැකෝ යන්ත්‍ර </router-link></h4>
                        <h4>    <router-link @click="scrollToTop()" to="" class="btn">Gully Bowser | ගලි බව්සර් </router-link></h4>
                            <h4>    <router-link @click="scrollToTop()" to="" class="btn">Stadiums | ක්‍රීඩාංගන </router-link></h4>
                                <h4>   <router-link @click="scrollToTop()" to="" class="btn">Town Hall | නගර ශාලාව </router-link></h4>
                                    <h4>   <router-link @click="scrollToTop()" to="" class="btn">crematoria | අදාහනාගාර </router-link></h4>
             
                    </div>
          
                    
                </div>

                <div class="grid col-md-8">
                    <h4>ඔබගේ මාර්ගගත වෙන් කිරීම් සඳහා, ඔබට අයත් සභාව සහ සේවාව තෝරන්න</h4>
                    <form id="ShopRentForm" @submit="handleSubmit" novalidate autocomplete="off">
                        
                 <div class="col-sm-12">
                    <label id="uProvince"><b>Select Province | පළාත තෝරන්න:</b>
                    </label>
                       
                           <select  @change="changeprovince"   v-model="BookingObj.pro"  name="province" id="province" class="form-control">
                                <option value="" selected disabled>Choose</option>
                                <option v-for="f in province" v-bind:key="f.pro_id" v-bind:value="f.pro_code">{{ f.pro_name }} </option>
                                
                                
                            </select>
                
                    <p class="error-mess" v-if="errorObj.proErr.length > 0">{{ errorObj.proErr[0] }}</p>

                    
                </div>
                <div class="col-sm-12">
                    <label id="uDistrict"><b>Select District | දිස්ත්‍රික්කය තෝරන්න:</b>
                    </label>
                    
                            <select class="form-control" @change="changedist" name="district" id="district" v-model="BookingObj.dis" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ds in Districts" :key="ds.dist_code" :value="ds.dist_code">{{ ds.dist_name }} </option>
                            </select>
                                
                    <p class="error-mess" v-if="errorObj.disErr.length > 0">{{ errorObj.disErr[0] }}</p>
                    
                </div>
                <div class="col-sm-12">
                    <label id="uPradeshiya"><b>Select Pradeshiya Sabha | ප්‍රාදේශීය සභාව තෝරන්න:</b>
                    </label>
                    
                            <select @change=changeSabha() class="form-control" name="pradeshiya" id="pradeshiya" v-model="BookingObj.pra_sabha" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ps in p_sabha" :key="ps.sb_code" :value="ps.sb_code">{{ ps.sb_name_en}}&nbsp;&nbsp;|&nbsp;&nbsp;{{ ps.sb_name_sin }} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.praErr.length > 0">{{ errorObj.praErr[0] }}</p>
                               
                </div>
                <div class="col-sm-12">
                    <label id="uPradeshiya"><b>Select Online Service | සේවාව තෝරන්න:</b>
                    </label>
                    
                            <select @change=changeservice() class="form-control" name="service" id="service" v-model="BookingObj.service" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ss in sabhaServices" :key="ss.vote"  v-bind:value="[ss.vote,ss.service_type]">{{ ss.service_name}} </option>

                            </select>
                            <p class="error-mess" v-if="errorObj.serErr.length > 0">{{ errorObj.serErr[0] }}</p>
                               
                </div>

                <div class="row col-sm-12" id="common" style="display: none;">
                   
                    <div class="col-sm-8">
                    <label for="name"><b>Your Name | ඔබගේ නම </b>
                    </label>
                    <input type="text" name="name"  id="name" class="form-control" v-model="BookingObj.cusName">
                            <p class="error-mess" v-if="errorObj.cusNameErr.length > 0">{{ errorObj.cusNameErr[0] }}</p>
                     </div>
                     <div class="col-sm-12">
                    <label for="address"><b>Your Address | ඔබගේ ලිපිනය </b>
                    </label>
                    <input type="text" name="address"  id="address" class="form-control" v-model="BookingObj.address">
                            <p class="error-mess" v-if="errorObj.addressErr.length > 0">{{ errorObj.addressErr[0] }}</p>
                     </div>
                     <div class="col-sm-6">
                    <label for="address"><b>Telephone No. | දුරකතන අංකය </b>
                    </label>
                    <input type="text" name="mobile"  id="mobile" class="form-control" v-model="BookingObj.mobile">
                            <p class="error-mess" v-if="errorObj.mobileErr.length > 0">{{ errorObj.mobileErr[0] }}</p>
                     </div>
                </div>
                 <div class="row col-sm-12" id="gully" style="display: none;">
                     <div class="col-sm-8">
                        <label id="uPradeshiya"><b>Select Service Area | සේවාව ලබා ගන්නා සීමාව:</b>
                    </label>
                    
                            <select class="form-control" name="sarea" id="sarea" v-model="BookingObj.sarea" >
                                <option value="" selected disabled>Choose</option>
                                <option value="Inside">Inside Town Area | නගර සීමාව තුල </option>
                                <option value="Outside">Outside Town Area | නගර සීමාවෙන් පිට </option>

                            </select>
                            <p class="error-mess" v-if="errorObj.sareaErr.length > 0">{{ errorObj.sareaErr[0] }}</p>
                    </div>
                    <div class="col-sm-6">
                    <label for="address"><b>Request Date | ඉල්ලා සිටින දිනය </b>
                    </label>
                    <input type="date" name="reqDateFrom"  id="reqDateFrom" class="form-control" v-model="BookingObj.reqDateFrom">
                            <p class="error-mess" v-if="errorObj.reqDateFromErr.length > 0">{{ errorObj.reqDateFromErr[0] }}</p>
                     </div>

                </div>
<!-- /////////End Gully and Bacho////////////////////////-->

            <div class="col-sm-12" id="stadium" style="display: none;">
                <div class="row">
                <div class="col-sm-8">
                        <label id="uPradeshiya"><b>Sport | ක්‍රීඩාව:</b>
                    </label>
                    <input type="text" name="sport"  id="sport" class="form-control" v-model="BookingObj.reqReason">
                            <p class="error-mess" v-if="errorObj.sportErr.length > 0">{{ errorObj.sportErr[0] }}</p>
                    </div>
                </div>
                    <div class="row col-sm-12">
                        <label for="address"><b>Request Date | ක්‍රීඩාංගනය ඉල්ලා සිටින දිනය </b>
                    </label>
                    </div>
                    <div class="row col-sm-12">
                    <div class="col-sm-6">
                        <label for="address"><b>From | සිට</b>
                    </label>
                    <input type="date" name="sportreqDate"  id="sportreqDate" class="form-control" v-model="BookingObj.reqDateFrom">
                            <p class="error-mess" v-if="errorObj.reqDateFromErr.length > 0">{{ errorObj.reqDateFromErr[0] }}</p>
                            
                            </div>
                            <div class="col-sm-6">
                    <label for="address"><b> To | දක්වා </b>
                    </label>
                    <input type="date" name="sportreqDate"  id="sportreqDate" class="form-control" v-model="BookingObj.stReqDateTo">
                            <p class="error-mess" v-if="errorObj.stReqDateToErr.length > 0">{{ errorObj.stReqDateToErr[0] }}</p>
                     </div>
                     </div>

                     <div class="row col-sm-12">
                        <label for="address"><b>Sports Time | ක්‍රීඩා පවත්වන වේලාව </b>
                    </label>
                    </div>
                    <div class="row col-sm-12">
                    <div class="col-sm-6">
                        <label for="address"><b>From | සිට</b>
                    </label>
                    <input type="time" name="sporttimefrom"  id="sporttimefrom" class="form-control" v-model="BookingObj.starttime">
                            <p class="error-mess" v-if="errorObj.starttimeErr.length > 0">{{ errorObj.starttimeErr[0] }}</p>
                            
                            </div>
                            <div class="col-sm-6">
                    <label for="address"><b> To | දක්වා </b>
                    </label>
                    <input type="time" name="sporttimeto"  id="sporttimeto" class="form-control" v-model="BookingObj.endTime">
                            <p class="error-mess" v-if="errorObj.endTimeErr.length > 0">{{ errorObj.endTimeErr[0] }}</p>
                     </div>
                     </div>
              </div>
<!-- /////////End Stadium ////////////////////////-->
            <div class="col-sm-12 " id="townhall" style="display: none;">
                <div class="row">
                <div class="col-sm-8">
                        <label id="uPradeshiya"><b>Reason | නගර/ රැස්වීම් ශාලාව අවශ්‍ය කාරණය:</b>
                    </label>
                    <input type="text" name="sport"  id="sport" class="form-control" v-model="BookingObj.reqReason">
                            <p class="error-mess" v-if="errorObj.reqReasonErr.length > 0">{{ errorObj.reqReasonErr[0] }}</p>
                    </div>
                </div>
                <div class="row">
                <div class="col-sm-6">
                    <label for="address"><b>Request Date | අවශ්‍ය දිනය</b>
                    </label>
                    <input type="date" name="reqDateFrom"  id="reqDateFrom" class="form-control" v-model="BookingObj.reqDateFrom">
                            <p class="error-mess" v-if="errorObj.reqDateFromErr.length > 0">{{ errorObj.reqDateFromErr[0] }}</p>
                     </div>
                     </div>
                 
                     <div class="row col-sm-12">
                        <label for="address"><b>Program | වැඩසටහන  </b>
                    </label>
                    </div>
                    <div class="row col-sm-12">
                    <div class="col-sm-6">
                        <label for="address"><b>Start Time | පටන් ගන්නා වේලාව</b>
                    </label>
                    <input type="time" name="sporttimefrom"  id="sporttimefrom" class="form-control" v-model="BookingObj.starttime">
                            <p class="error-mess" v-if="errorObj.starttimeErr.length > 0">{{ errorObj.starttimeErr[0] }}</p>
                            
                            </div>
                            <div class="col-sm-6">
                    <label for="address"><b> End Time | අවසන් කරන වේලාව </b>
                    </label>
                    <input type="time" name="sporttimeto"  id="sporttimeto" class="form-control" v-model="BookingObj.endTime">
                            <p class="error-mess" v-if="errorObj.endTimeErr.length > 0">{{ errorObj.endTimeErr[0] }}</p>
                     </div>
                    </div>
               <br>
                    <div class="row col-sm-12">
                    <div class="col-sm-3">
                        <label for="address"><b>Request Seats | අවශ්‍ය ආසන ගණන</b>
                    </label>
                    <input type="text" name="reqSeats"  id="reqSeats" class="form-control" v-model="BookingObj.reqSeats">
                            <p class="error-mess" v-if="errorObj.reqSeatsErr.length > 0">{{ errorObj.reqSeatsErr[0] }}</p>
                            
                            </div>
                            <div class="col-sm-3">
                    <label for="address"><b> If Any Payments | මුදල් අය කරනු ලබන්නේද? </b>
                    </label>
                             <select class="form-control" name="anyPaments" id="anyPaments" v-model="BookingObj.reqGetPayment" >
                                <option value="" selected disabled>Choose</option>
                                <option value="yes">Yes | ඔව් </option>
                                <option value="no">No | නැත </option>

                            </select>
                            <p class="error-mess" v-if="errorObj.reqGetPaymentErr.length > 0">{{ errorObj.reqGetPaymentErr[0] }}</p>
                     </div>
                     </div>
                <div class="row col-sm-12">
                     <div class="col-sm-12">
                        <label id="uPradeshiya"><b>Guests | සහභාගිවීමට නියමිත අමුත්තන්:</b>
                    </label>
                    <textarea name="sport"  id="sport" class="form-control" v-model="BookingObj.reqGuests"></textarea>
                    <!-- <input type="textarea" name="sport"  id="sport" class="form-control" v-model="BookingObj.reqGuests"> -->
                            <p class="error-mess" v-if="errorObj.reqGuestsErr.length > 0">{{ errorObj.reqGuestsErr[0] }}</p>
                    </div>
                </div>
                    
            </div>
            <!-- /////////End town hall ////////////////////////-->

            <div class="row col-sm-12" id="crematoria" style="display: none;">
                <div class="col-sm-8">
                    <label for="guardianName"><b>Guardian's Name | භාරකරුගේ නම </b>
                    </label>
                    <input type="text" name="guardianName"  id="guardianName" class="form-control" v-model="BookingObj.guardianName">
                            <p class="error-mess" v-if="errorObj.guardianNameErr.length > 0">{{ errorObj.guardianNameErr[0] }}</p>
                     </div>

                     <!-- <div class="col-sm-12">
                    <label for="guardianaddress"><b>Your Address | ඔබගේ ලිපිනය </b>
                    </label>
                    <input type="text" name="guardianaddress"  id="guardianaddress" class="form-control" v-model="BookingObj.guardianaddress">
                            <p class="error-mess" v-if="errorObj.guardianaddressErr.length > 0">{{ errorObj.guardianaddressErr[0] }}</p>
                     </div>
                     <div class="col-sm-6">
                    <label for="address"><b>Telephone No. | දුරකතන අංකය </b>
                    </label>
                    <input type="text" name="guardianmobile"  id="guardianmobile" class="form-control" v-model="BookingObj.guardianmobile">
                            <p class="error-mess" v-if="errorObj.guardianmobileErr.length > 0">{{ errorObj.guardianmobileErr[0] }}</p>
                     </div> -->

                     <div class="col-sm-8">
                    <label for="deceasedName"><b>Deceased's name | මියගිය අයගේ නම </b>
                    </label>
                    <input type="text" name="deceasedName"  id="deceasedName" class="form-control" v-model="BookingObj.deceasedName">
                            <p class="error-mess" v-if="errorObj.deceasedNameErr.length > 0">{{ errorObj.deceasedNameErr[0] }}</p>
                     </div>
                     <div class="row col-sm-12">
                    <div class="col-sm-6">
                        <label for="address"><b>Deceased's Age | මියගිය අයගේ වයස</b>
                    </label>
                    <input type="text" name="deceasedage"  id="deceasedage" class="form-control" v-model="BookingObj.deceasedage">
                            <p class="error-mess" v-if="errorObj.deceasedageErr.length > 0">{{ errorObj.deceasedageErr[0] }}</p>
                            
                            </div>
                            <div class="col-sm-6">
                    <label for="deceasedSex"><b> Deceased's Sex | මියගිය අයගේ ස්ත්‍රී/පුරුෂ භාවය </b>
                    </label>
                   
                            <select class="form-control" name="deceasedSex" id="deceasedSex" v-model="BookingObj.deceasedSex" >
                                <option value="" selected disabled>Choose</option>
                                <option value="yes">Female | ගැහැණු </option>
                                <option value="no">Male | පිරිමි </option>

                            </select>
                            <p class="error-mess" v-if="errorObj.deceasedSexErr.length > 0">{{ errorObj.deceasedSexErr[0] }}</p>

                     </div>
                     </div>
                     <div class="row col-sm-12">
                    <div class="col-sm-6">
                        <label for="dateOfDeath"><b>Date of death | මියගිය දිනය</b>
                    </label>
                    <input type="date" name="dateOfDeath"  id="dateOfDeath" class="form-control" v-model="BookingObj.dateOfDeath">
                            <p class="error-mess" v-if="errorObj.dateOfDeathErr.length > 0">{{ errorObj.dateOfDeathErr[0] }}</p>
                            
                            </div>
                            <div class="col-sm-6">
                    <label for="PlaceOfDeath"><b> Place of death | මියගිය ස්ථානය </b>
                    </label>
                    <input type="text" name="PlaceOfDeath"  id="PlaceOfDeath" class="form-control" v-model="BookingObj.PlaceOfDeath">
                            <p class="error-mess" v-if="errorObj.PlaceOfDeathErr.length > 0">{{ errorObj.PlaceOfDeathErr[0] }}</p>
                     </div>
                     </div>

                     <div class="row col-sm-12">
                    <div class="col-sm-6">
                        <label for="certificate"><b>Death Certificate No | මරණ සහතිකයේ අංකය</b>
                    </label>
                    <input type="text" name="certificate"  id="certificate" class="form-control" v-model="BookingObj.certificate">
                            <p class="error-mess" v-if="errorObj.certificateErr.length > 0">{{ errorObj.certificateErr[0] }}</p>
                            
                            </div>
                            <div class="col-sm-6">
                    <label for="dateOfCremation"><b> Date of Cremation/Burial | ආදාහනය/භූමදානය කරන දිනය </b>
                    </label>
                    <input type="date" name="dateOfCremation"  id="dateOfCremation" class="form-control" v-model="BookingObj.reqDateFrom">
                            <p class="error-mess" v-if="errorObj.reqDateFromErr.length > 0">{{ errorObj.reqDateFromErr[0] }}</p>
                     </div>
                     </div>

                     <div class="row col-sm-12">
                    <div class="col-sm-6">
                        <label for="timeOfCremation"><b>Time of cremation/burial | ආදාහනය/භූමදානය කරන වේලාව</b>
                    </label>
                    <input type="time" name="timeOfCremation"  id="timeOfCremation" class="form-control" v-model="BookingObj.timeOfCremation">
                            <p class="error-mess" v-if="errorObj.timeOfCremationErr.length > 0">{{ errorObj.timeOfCremationErr[0] }}</p>
                            
                            </div>
                            <div class="col-sm-6">
                    <label for="monument"><b> Build a monument? | ස්මාරකයක් ඉදිකරන්නේද? </b>
                    </label>
                            <select class="form-control" name="monument" id="monument" v-model="BookingObj.monument" >
                                <option value="" selected disabled>Choose</option>
                                <option value="yes">Yes | ඔව් </option>
                                <option value="no">No | නැත </option>

                            </select>
                            <p class="error-mess" v-if="errorObj.monumentErr.length > 0">{{ errorObj.monumentErr[0] }}</p>
                     </div>
                     </div>

            </div>
            <!-- /////////End crematoria ////////////////////////-->
            <div class="row col-sm-12" id="bacho" style="display: none;">
            </div>
            <!-- /////////End bacho ////////////////////////-->

            <div class="row col-sm-12" id="subButton"  style="display: none;">
                <!-- <input type="button" name="reqDateFrom"  id="reqDateFrom" class="form-control" > -->
                <div class="col-sm-4">
                <input type="submit" value="Send Request>>" class="btn1 " />
                 </div>
            </div>
           </form>             
            </div>
            </div>

        </div>

       <div>
        <!-- <h4><b>දැනට මෙම සේවාවන් මාර්ගගතව සිදු කිරීම සඳහා අවශ්‍ය කටයුතු සිදු කරමින් පවතී.හැකි ඉක්මනින් සේවාව ලබා දීමට කටයුතු කරනු ලැබේ...</b></h4> -->

       </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import VueBasicAlert from 'vue-basic-alert'
export default {
    name: "Home",
    data() {
        return {
             matchshop: [],
             Nic:'',userNic:null,
             matchUser:undefined,
             BookingObj: { pro:"",dis:"",pra_sabha:"",service:"",cusName:"",address:"",mobile:"",sarea:"",
            sport:"",reqDateFrom:this.formattedDate(new Date()),stReqDateTo:this.formattedDate(new Date()),starttime:"00:00:00",endTime:"00:00:00",
            reqReason:"",reqSeats:0,reqGuests:0,reqGetPayment:"No",
            guardianName:"",deceasedName:"",deceasedage:"",deceasedSex:"",
            dateOfDeath:this.formattedDate(new Date()),PlaceOfDeath:"",certificate:"",timeOfCremation:"00:00:00",monument:"No",

            },
            errorObj: { disErr: [],proErr: [], praErr: [],serErr:[],cusNameErr:[],addressErr:[],mobileErr:[],sareaErr:[],reqDateFromErr:[],
                sportErr:[],stReqDateToErr:[],starttimeErr:[],endTimeErr:[],
                reqReasonErr:[],reqSeatsErr:[],reqGuestsErr:[],reqGetPaymentErr:[]   ,
                guardianNameErr:[],deceasedNameErr:[],deceasedageErr:[],deceasedSexErr:[],dateOfDeathErr:[],
                PlaceOfDeathErr:[],certificateErr:[],timeOfCremationErr:[],monumentErr:[]       
            },
            p_sabha:[],
            Districts: [],
            DistrictsData:undefined,
            sDist:undefined,
            province: [],
            sabhaServices:[],
            currentDate: new Date(),
                
                
    // matchUserdata: [],
        }
    },
    created() {
        this.getSessionData();
        this.AllDistricts();
           this.Allprovince();
           this.AllprSabha();
           this.getSessionData();
           
        
       },

    computed: {
        ...mapState(["user"]),
        
    },

    methods: {
       
        ...mapMutations(["setUser"]),
        // ...mapMutations(["setshopdata"]),
        formattedDate: function(curdate) {
         const day = curdate.getDate().toString().padStart(2, "0");
         const month = (curdate.getMonth() + 1)
           .toString()
           .padStart(2, "0");
         const year = curdate.getFullYear().toString();
         var defaultdate =`${year}-${month}-${day}`
        //  return `${year}-${month}-${day}`;
        return defaultdate
       },
        async Allprovince() {
           
           this.province = (await axios.get('/provinces/' )).data;
          
          },
          async AllDistricts() {
           this.Districts = (await axios.get('/districts/')).data;
           
          },
          async AllprSabha() {
           this.p_sabha = (await axios.get('/Prasabha/' )).data;
    
          },
     
          async changeprovince() {
  
                  let DistrictsData = (await  axios.get('/districts/' +this.BookingObj.pro));
                  this.Districts=DistrictsData.data
              
         },
        async changedist() {
              
              let SabhaData = (await axios.get('/Prasabha/' + this.BookingObj.dis));
              this.p_sabha=SabhaData.data;
            
              
         },
         async changeSabha() {
              
              let onlineServ = (await axios.get('/onlineservise/' + this.BookingObj.pra_sabha));
              this.sabhaServices=onlineServ.data;
            
              
         },
         
        scrollToTop() {
            window.scrollTo(0, 0);
        
        },
     
        async MatchUser(usernic) {
            let data = await axios.get('/customersdata/' + usernic);
            this.matchUser = data.data;
        },
        async getSessionData(){
         

            const data=JSON.parse(sessionStorage.getItem('Userdata'));
            if(data){
                this.Nic=data.Unic
                // await this.getMatchshop(this.Nic);
            await this.MatchUser(this.Nic);
            this.setUser(this.matchUser);

            this.BookingObj.cusName=this.matchUser.cus_name
            this.BookingObj.address=this.matchUser.cus_address
            this.BookingObj.mobile=this.matchUser.cus_contact
            await axios.delete('/onlinepaymenttemp/'+this.user.cus_nic );
            }
                    // if (this.userNic === null) {
           
           
        },
        changeservice(){
            if(this.BookingObj.service[1]=='gully'){
                document.getElementById('common').style.display="block"
                document.getElementById('gully').style.display="block"
                document.getElementById('subButton').style.display="block"
                document.getElementById('stadium').style.display="none"
                document.getElementById('townhall').style.display="none"
                document.getElementById('crematoria').style.display="none"
                

            }else if(this.BookingObj.service[1]=='bacho'){
                document.getElementById('common').style.display="block"
                document.getElementById('gully').style.display="block"
                document.getElementById('subButton').style.display="block"
                document.getElementById('stadium').style.display="none"
                document.getElementById('townhall').style.display="none"
                document.getElementById('crematoria').style.display="none"

            } 
            else if(this.BookingObj.service[1]=='stadiums'){
                document.getElementById('common').style.display="block"
                document.getElementById('stadium').style.display="block"
                document.getElementById('subButton').style.display="block"
                document.getElementById('gully').style.display="none"
                document.getElementById('townhall').style.display="none"
                document.getElementById('crematoria').style.display="none"
               
            } 
            else if(this.BookingObj.service[1]=='crematoria'){
                document.getElementById('common').style.display="block"
                document.getElementById('stadium').style.display="none"
                document.getElementById('subButton').style.display="block"
                document.getElementById('gully').style.display="none"
                document.getElementById('townhall').style.display="none"
                document.getElementById('crematoria').style.display="block"

            } 
            else if(this.BookingObj.service[1]=='townhall'){
                document.getElementById('common').style.display="block"
                document.getElementById('townhall').style.display="block"
                document.getElementById('subButton').style.display="block"
                document.getElementById('gully').style.display="none"
                document.getElementById('stadium').style.display="none"
                document.getElementById('crematoria').style.display="none"

            }
            else{
                document.getElementById('gully').style.display="none"
                document.getElementById('common').style.display="none"
                document.getElementById('stadium').style.display="none"
                document.getElementById('townhall').style.display="none"
                document.getElementById('crematoria').style.display="none"
            }
        },
        resetCheckErr: function () {
            this.errorObj.cusNameErr = [];
            this.errorObj.addressErr= [];
            this.errorObj.mobileErr = [];
            this.errorObj.sareaErr = [];
            this.errorObj.reqDateFromErr = [];
            this.errorObj.serErr = [];
           
        },

        resetform: function () {
            this.BookingObj.pro ='';
            this.BookingObj.dis= '';
            this.BookingObj.pra_sabha ='';
            this.BookingObj.service ='';
           
        },

        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },

        checkForm: function () {
            this.resetCheckErr();

            // Name validate
            if (!this.BookingObj.cusName) {
                this.errorObj.na.push("Entering a name is required");
            }
            else {
                if (!/^[A-Za-z]+$/.test(this.BookingObj.cusName.replace(/\s/g, ""))) {
                    this.errorObj.cusNameErr.push('A name can only contain letters');
                }
            }
// Adress validate
if (!this.BookingObj.address) {
                this.errorObj.addressErr.push("Entering a address is required");
            }
           // ReqDate validate
if (!this.BookingObj.reqDateFrom) {
                this.errorObj.reqDateFromErr.push("Entering a date is required");
            }
           
            // Phone validate
            if (!this.BookingObj.mobile) {
                this.errorObj.mobileErr.push('Entering phone number is required');
            }
            // else {
                // if (!this.BookingObj.phone.startsWith('84')) {
                //     this.errorObj.phoneErr.push('Phone numbers must start with 84');
                // }

            //     if (this.BookingObj.mobile.length != 10) {
            //         this.errorObj.phoneErr.push('Phone numbers must have exactly 10 digits');
            //     }
            //     else{
            //         if(!/[0-9]{10}/.test(this.BookingObj.mobile)) {
            //         this.errorObj.phoneErr.push('Phone numbers should contain 10 Digits, no spaces allowed');
            //         }
            //      }
            // }
 
     
        },
        async handleSubmit(e) {
           this.checkForm();
           
                if (!this.checkEmptyErr()) {
                    e.preventDefault();
                } else {
                 e.preventDefault();
                
                    if(!this.user){
                        this.$refs.alert.showAlert('error','Please Loging to Continue !')
                    }
                    else{
                     
                        const bookingData = {
                        service_type:this.BookingObj.service[1],
                        sabha_code:this.BookingObj.pra_sabha,
                        user_nic: this.user.cus_nic,
                        name: this.BookingObj.cusName,
                        address: this.BookingObj.address,
                        mobile: this.BookingObj.mobile,
                        amount: '0',
                        payment_status: 'waiting',
                        feedback:'',
                        req_date_from:this.BookingObj.reqDateFrom,
                        req_date_to:this.BookingObj.stReqDateTo,
                        req_start_time:this.BookingObj.starttime,
                        req_end_time:this.BookingObj.endTime,
                        req_reason:this.BookingObj.reqReason,
                        vote:this.BookingObj.service[0],
                        service_area:this.BookingObj.sarea,
                        req_seats:this.BookingObj.reqSeats,
                        req_guests:this.BookingObj.reqGuests,
                        req_get_payments:this.BookingObj.reqGetPayment,
                        vat:'0',
                        stamp:'0',
                        nation_vat:'0',
                        dead_person_guardian:this.BookingObj.guardianName,
                        dead_person_name:this.BookingObj.deceasedName,
                        dead_person_age:this.BookingObj.deceasedage,
                        dead_person_sex:this.BookingObj.deceasedSex,
                        dead_date:this.BookingObj.dateOfDeath,
                        dead_place:this.BookingObj.PlaceOfDeath,
                        dead_cer_num:this.BookingObj.certificate,
                        dead_cer_name:'',
                        headstone:this.BookingObj.monument,

                        }
                        await axios.post("/onbookingrequests/",bookingData);
                        this.$refs.alert.showAlert('success', "Your Request was Send ");
                        // this.resetform()
                        this.$router.push("/onlinebookinghistory");
                       
                        
                }
            }
        }
       
    //    
       
    },
    components: {
        VueBasicAlert,
        
    }

};
</script>

<style scoped>

.home {
    min-height: 70vh;
    
}

.home-main,
.home-about,
.home-banner,
.home-category {
    padding: 2rem 9%;
}

.home-main {
    background-image: url("../assets/images/loginbackground1.jpg");
    /* width: 100%; */
    /* height: 50%; */
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size:cover;
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    gap: 2rem;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.home-main .content {
    padding: 2rem;
    text-align: center;
    border-radius: .5rem;
    /* background: #f7f7f7; */
    padding-left: 50rem;
    height: 27vh;
}


.home-main .content span {
    font-size: 2rem;
    color: #620a0a;
}

.home-main .content h3 {
    font-size: 3rem;
    color: #130f40;
    padding-top: .5rem;
}

.home-main .content p {
    font-size: 1.6rem;
    color: #666;
    line-height: 2;
    padding: 1rem 0;
}

.home-main .image {
    flex: 1 1 41rem;
    margin: 2rem 0;
    pointer-events: none;
}

.home-main .image .home-img {
    width: 100%;
    margin-top: 5rem;
}

.home-main .home-parallax-img {
    position: absolute;
    top: -15rem;
    right: -15rem;
    width: 80vw;

}


/* home category */
.home-category {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    gap: 1.5rem;
    padding-bottom: 5rem;
}

.home-category .box {
    padding: 2rem;
    text-align: center;
    border-radius: .5rem;
    background: #f7f7f7;
}

.home-category .box:hover {
    background: #620a0a;
}

.home-category .box:hover h3 {
    color: #fff;
}

.home-category .box img {
    height: 7rem;
}

.home-category .box h3 {
    font-size: 1.8rem;
    color: #130f40;
}


/* home banner */
.home-banner .row-banner {
    background: url(../assets/images/row-banner.png) no-repeat;
    height: 45rem;
    background-size: cover;
    background-position: center;
    position: relative;
}

.home-banner .row-banner .content {
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translateY(-50%);
}

.home-banner .row-banner .content span {
    font-family: 'Satisfy', cursive;
    font-size: 2rem;
    color: #620a0a;
    color: #130f40;
}

.home-banner .row-banner .content h3 {
    font-size: 6rem;
    color: red;
    text-transform: uppercase;
}

.home-banner .row-banner .content p {
    font-size: 2rem;
    padding-bottom: 1rem;
}


.home-banner .grid-banner .grid {
    border-radius: 1rem;
    overflow: hidden;
    height: auto;
    font-size: 1.5rem;
    
    /* height: 20rem; */
}
.home-banner .grid-banner .grid .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: bottom 1px solid; 
} 

.home-banner .grid-banner .grid:hover img {
    transform: scale(1.2);
}

.home-banner .grid-banner .grid img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.home-banner .grid-banner .grid .content {
    position: absolute;
    top: 2rem;
    padding: 0 2rem;
}

.home-banner .grid-banner .grid .btn {
    background: #110262;
}
.btn1 {
    margin: 1rem 0;
   width: 90%;
   text-align: center;
   background-color : #620a0a;
   height: 30px;
   font-size: 1.2rem; 
   color: #fffafa;
   border-radius: 1cap;
}

.home-banner .grid-banner .grid .content.center {
    text-align: center;
    width: 100%;
}

.home-banner .grid-banner .grid .content.center span {
    color: #ca9206;
}

.home-banner .grid-banner .grid .content.center h3 {
    color: #130f40;
}

.home-banner .grid-banner .grid .content span {
    font-size: 1.5rem;
    color: #fff;
}

.home-banner .grid-banner .grid .content h3 {
    font-size: 1rem;
    color: #fff;
    padding-top: .5rem;
}

.home-about {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    background: #f7f7f7;
}

.home-about .image {
    flex: 1 1 40rem;
}

.home-about .image img {
    width: 100%;
}

.home-about .content {

    flex: 1 1 40rem;
}

.home-about .content span {
    font-family: 'Satisfy', cursive;
    font-size: 3rem;
    color: #620a0a;
}

.home-about .content .title {
    font-size: 3rem;
    padding-top: .5rem;
    color: #130f40;
}

.home-about .content p {
    padding: 1rem 0;
    line-height: 2;
    font-size: 1.4rem;
    color: #666;
}

.home-about .content .icons-container {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.home-about .content .icons-container .icons {
    flex: 1 1 20rem;
    border-radius: .5rem;
    background: #fff;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}

.home-about .content .icons-container .icons h3 {
    font-size: 1.7rem;
    color: #130f40;
}

@media (max-width: 768px) {
    #menu-btn {
        display: inline-block;
    }

    .home-main .home-parallax-img {
        top: 0;
        right: 0;
        width: 100%;
    }

    .home-banner .grid-banner .content h3 {
        font-size: 15px !important;
    }

    .home-banner .grid-banner .content.center {
        padding-left: 0px !important;
    }

}

@media (max-width: 576px) {
    .home-main .content h3 {
        font-size: 1rem;
    }

    .home-main .content p {
        font-size: 1.5rem;
    }
} 
.thank-container {
    padding: 2rem 9%;
    background: #fff;
    height: 20vh;
}


.thank-container h1 {
    width: 100%;
    /* height: 25px; */
    margin: auto;
    display: block;
    text-align: center;
    /* margin-top: 10px; */
}

.thank-container h1  span {
    position: relative;
    color: #620a0a !important;
    /* top: 40px; */
    display: inline-block;
    /* -webkit-animation: bounce 0.3s ease infinite alternate; */
    font-size: 25px;
    color: #fff;
    text-transform: none;
    /* text-shadow: 0 1px 0 #620a0a, 0 2px 0 #620a0a, 0 3px 0 #620a0a, 0 4px 0 #620a0a,
        0 5px 0 #620a0a, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
        0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4); */
}

/* .thank-container h1 p span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
}

.thank-container h1 p span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
}

.thank-container h1 p span:nth-child(4) {
    -webkit-animation-delay: 0.3s;
}

.thank-container h1 p span:nth-child(5) {
    -webkit-animation-delay: 0.4s;
} */

.thank-letter {
    position: relative;
    /* top: 180px; */
    font-size: 20px;
    width: 40vw;
    margin: 0 auto;
    text-align: center;
}
.thank-letter .btn {
        background: #620a0a;
    }
.btn {
        background: #620a0a;
}
pre{
 padding-right: 5%;
 padding-top: 1%;
}
.thank-letter a {
    margin-top: 20px;
}

/* @-webkit-keyframes bounce {
    100% {
        top: -20px;
        text-shadow: 0 1px 0 #620a0a, 0 2px 0 #620a0a, 0 3px 0 #620a0a, 0 4px 0 #620a0a,
            0 5px 0 #620a0a, 0 6px 0 #620a0a, 0 7px 0 #620a0a, 0 8px 0 #620a0a, 0 9px 0 #620a0a,
            0 50px 25px rgba(0, 0, 0, 0.2);
    }
} */

@media (max-width: 1024px) {
    .thank-container {
        padding: 0px;
    }

    .thank-container h1 {
        margin-top: 20px;
    }

    .thank-container h1 p span {
        font-size: 10px;
    }
   

    .thank-letter {
        font-size: 16px;
        width: 50vw;
        top: 160px;
    }
}

@media (max-width: 576px) {

    .thank-container h1 {
        margin-top: 50px;
    }

    .thank-container h1 p span {
        font-size: 10px;
    }

    .thank-letter {
        font-size: 14px;
        width: 90vw;
        top: 90px;
    }
}
.register-container {
    padding: 1.5rem 9%;
    /* padding-top: 25rem; */
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    /* padding: 1rem; */
    border-radius: 0.5rem;
 
    /* animation: fadeUp 0.4s linear; */
}

.register-container .register-form-container form h3 {
    /* padding-bottom: 0.1rem; */
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form h4 {
    /* padding-bottom: 0.2rem; */
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: left;
    
}

.register-container .register-form-container form h5 {
    padding-bottom: 0.2rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form pre {
    /* padding-bottom: 0.2rem; */
    font-size: 1.0rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: right;
    
}
.register-container .register-form-container form h6 {
    /* padding-bottom: 0.2rem; */
    font-size: 1.25rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: left;
    
}
.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: none;
   
}

.register-container .register-form-container form label {
    font-size: 1rem;
    margin: 0;
    padding: 0.6rem;
}

.register-container .register-form-container form span {
    font-size: 15px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #620a0a;
}


.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 20%;
    text-align: center;
    align-items: center;
    background: #620a0a;
    
}


.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 100%;

}

 
/* Table */
.bg-light-gray {
    background-color: #f7f7f7;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 0px solid #dee2e6;
}

.table-responsive{
    padding-left: 3.5rem;
    width: 95%;
  /* align:center; */
}

.table-responsive1{
    padding-left: 6rem;
    /* width: 95%; */
  /* align:center; */
}
.table1{
  align-items: center;
    width: 95%;
    font-size:xx-small;
   
  /* align:center; */
}
.table1 td,
.table th {
    padding: .15rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    width: auto;
    text-align: center;
} 
.table td,
.table th {
    padding: .25rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    width: 30%;
} 
.aling{
    align-items: center;
    text-align: center;
align-content: center;
/* padding-right: 15%; */
}  
</style>




