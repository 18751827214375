<template>
    <div class="header">
        <router-link @click="scrollToTop()" to="/" class="logo"><img src="../assets/images/header.png" alt="" />
        </router-link>

        <h1>Ministry of Public Administration,<br>Home Affairs,Provincial Councils and Local Government</h1> 
        <!-- <h6>Independence Square,Colobo 7,Sri Lanka</h6> -->
        <div>
            <!-- <h1>පළාත් සභා සහ පළාත් පාලන අමාත්‍යාංශය</h1> -->
            <!-- <h1>Public Administration,<br>Home Affairs,Provincial Councils and Local Government</h1>  -->
                <!-- <img src="../assets/images/b.png" alt="" class="home-img"> -->
                <!-- <img src="../assets/images/a.png" alt="" class="home-parallax-img"> -->
           </div>
            
       <div class="icons">

           
            <router-link to="/login" v-slot="{ href, navigate }">
                <div v-if="!user" class="fas fa-user account" >
                    <h5 @click="scrollToTop(), navigate,href">Login</h5>
                </div>
                </router-link>
            <router-link to="/register" v-slot="{ href, navigate }">
                <div v-if="!user" class="fas fa-user-plus account" >
                    <h5 @click="scrollToTop(), navigate,href">Register</h5>
                </div>
                </router-link>
                <router-link to="/" v-slot="{ href, navigate }">
                <div v-if="user" class="fas fa-home account" >
                    <h5 @click="scrollToTop(), navigate,href">Home</h5>
                </div>
                </router-link>
            
            

            <router-link to="/profile" v-slot="{ href, navigate }">
                <div v-if="user" class="fas fa-user-plus account" style="background: #8594de;color: white;" @click="showLog">
                    <h5 @click="scrollToTop(), navigate,href">Profile</h5>
                </div>
                </router-link>
            <router-link to="/changepassword" v-slot="{ href, navigate }">
                <div v-if="user" class="fas fa-key account" style="background: #e11736; color: white;" @click="showLog">
                    <h5 @click="scrollToTop(), navigate,href">Pass</h5>
                </div>
                </router-link>
                <router-link @click="handleLogout" to="/" v-slot="{ href, navigate }">
                <div v-if="user" class="fas fa-user account" style="background: #ad8d2d;color: white;" @click="showLog">
                    <h5 @click="scrollToTop(), navigate,href">logout</h5>
                </div>
                </router-link>
            
<h5 v-if="user">Hello {{ user.cus_name }} </h5>

    
        </div>
       
    </div>
</template>

<script>
// import axios from "axios";
import { mapState, mapMutations } from "vuex";
export default {
    name: 'NavBar',
    // data() {
    //     return {

    //          nic: "", name: "",
    //         // userNic:window.sessionStorage.getItem('usernic'),
          
    //     }
    // }, 
    // created() {
           
    //         this.getSessionData()
           
    //     },
       
       
    computed: {
        ...mapState(["user"])
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
     
   
        ...mapMutations(["setUser"]),

        
        
        scrollToTop() {
            window.scrollTo(0, 0);
        },

        showNav: function () {
            let navbar = document.querySelector('.header .navbar');
            navbar.classList.toggle('active');
        },

        // showLog: function () {
        //     let mq = window.matchMedia("(max-width: 768px)");
        //     if (mq.matches) {
        //         let log = document.querySelector('.drop-down-select');
        //         // log.classList.toggle('active');
        //     }
        // },

        // handleScroll: function () {
        //     let navbar = document.querySelector('.header .navbar');
        //     navbar.classList.remove('active');
        //     let log = document.querySelector('.drop-down-select');
        //     log.classList.remove('active');
        // },

        
        handleLogout: function () {
            this.setUser("");
            // this.name="";
            // this.nic="",
            window.sessionStorage.clear();
            window.sessionStorage.removeItem('User');
            this.$router.push("/login");
        }
    }
}
</script>

<style scoped>
.header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #550202;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 9%;
    /* justify-self: unset; */
}

.header .logo {
    font-size: 2.5rem;
    font-weight: bolder;
    color: #f1f0f3;
    
}

.header .logo img {
    /* padding-right: 0rem; */
    color: #847b03;
}


.header .icons div {
    height: 4.5rem;
    width: 5rem;
    line-height: 4.5rem;
    font-size: 2rem;
    background: #eb8e02;
    color: #f8f7fc;
    border-radius: .5rem;
    margin-left: .3rem;
    cursor: pointer;
    text-align: center;
        /* justify-content: space-between; */
}
h5{
 
    color: #f8f7fc;
    text-align: center;
}
h1{
 
 color: #f8f7fc;
 text-align: left;
 padding-left: 0%;
 /* padding-right: 35%; */
}
.header .icons div:hover {
    color: #fff;
    background: #f4f5f1 !important;
}


#menu-btn {
    display: none;
}




@media (max-width: 768px) {
    .header .navbar {
        position: absolute;
        top: 99%;
        left: 0;
        right: 0;
        background: #fff;
        border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    .header .navbar.active {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    .header .navbar a {
        font-size: 2rem;
        margin: 2rem;
        display: block;
    }

    #menu-btn {
        display: inline-block;
    }

}

@media (max-width: 576px) {
    .header .navbar a {
        font-size: 1.5rem;
        margin: 0;
    }
}
</style>