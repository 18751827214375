<template>
    <div class="thank-container">
        <h1>
            <p>
                <span>C</span>
                <span>o</span>
                <span>m</span>
                <span>i</span>
                <span>n</span>
                <span>g</span>
            </p>

            <p>
                <span>S</span>
                <span>o</span>
                <span>o</span>
                <span>n</span>
            </p>
        </h1>

        <div class="thank-letter">
           
            <p v-none>
               Please Wait.This Page is under contsruction.
                We always provide you with the best
                experience.
            </p>
            <router-link class="btn" to="/">Back to Home page</router-link>
        </div>
    </div>
</template>


<script>
export default {
    name: 'UnderConstruction',
    
    // created() {
    //         this.ClearSession();
          
    //     },
    //     methods: {
        
    //     async ClearSession() {
    //      window.sessionStorage.clear();
    //      sessionStorage.removeItem('User');
    //     },
    // }
}
</script>
<script setup>
// enables v-focus in templates
const vNone = {
    mounted(el) {
        el.style.textTransform = "none";
    }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Luckiest+Guy");

.thank-container {
    padding: 2rem 9%;
    background: #fff;
    height: 90vh;
}


.thank-container h1 {
    width: 100%;
    height: 100px;
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 50px;
}

.thank-container h1 p span {
    position: relative;
    color: #620a0a !important;
    top: 20px;
    display: inline-block;
    -webkit-animation: bounce 0.3s ease infinite alternate;
    font-size: 80px;
    color: #fff;
    text-shadow: 0 1px 0 #620a0a, 0 2px 0 #620a0a, 0 3px 0 #620a0a, 0 4px 0 #620a0a,
        0 5px 0 #620a0a, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
        0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
}

.thank-container h1 p span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
}

.thank-container h1 p span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
}

.thank-container h1 p span:nth-child(4) {
    -webkit-animation-delay: 0.3s;
}

.thank-container h1 p span:nth-child(5) {
    -webkit-animation-delay: 0.4s;
}

.thank-letter {
    position: relative;
    top: 180px;
    font-size: 20px;
    width: 40vw;
    margin: 0 auto;
    text-align: center;
}
.thank-letter .btn {
        background: #620a0a;
    }
.thank-letter a {
    margin-top: 20px;
}

@-webkit-keyframes bounce {
    100% {
        top: -20px;
        text-shadow: 0 1px 0 #620a0a, 0 2px 0 #620a0a, 0 3px 0 #620a0a, 0 4px 0 #620a0a,
            0 5px 0 #620a0a, 0 6px 0 #620a0a, 0 7px 0 #620a0a, 0 8px 0 #620a0a, 0 9px 0 #620a0a,
            0 50px 25px rgba(0, 0, 0, 0.2);
    }
}

@media (max-width: 1024px) {
    .thank-container {
        padding: 0px;
    }

    .thank-container h1 {
        margin-top: 20px;
    }

    .thank-container h1 p span {
        font-size: 70px;
    }

    .thank-letter {
        font-size: 16px;
        width: 50vw;
        top: 160px;
    }
}

@media (max-width: 576px) {

    .thank-container h1 {
        margin-top: 50px;
    }

    .thank-container h1 p span {
        font-size: 50px;
    }

    .thank-letter {
        font-size: 14px;
        width: 90vw;
        top: 90px;
    }
}
</style>