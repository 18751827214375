import { createWebHistory, createRouter } from "vue-router";
// import Vue from "vue";
// import VueRouter from "vue-router";


import Login from '../pages/Login.vue';
import Register from '../pages/Register.vue';
import Home from '../pages/Home.vue';
import Profile from '../pages/Profile.vue';
import Thank from '../pages/Thank.vue';
import Shoprent from '../pages/Shoprent.vue';
import ShoprentExist from '../pages/Shoprentexit.vue';
// import ShoprentInvoice from '../pages/ShoprentInvoice.vue';
import ShoprentInvoice2 from '../pages/ShoprentInvoice2.vue';

import UnderConstruction from '../pages/UnderConstruction.vue';
// import Successful from '../pages/Successful.vue';
import Cancel from '../pages/Cancel.vue';
import OnlineBooking from '../pages/OnlineBooking.vue';
import ChangePassword from'../pages/changepass.vue';
import AssessmentTax from '../pages/AssessmentTax.vue';
import AssessmentTaxInvoice from '../pages/AssessmentTaxInvoice.vue';
import AssessmentPayHistory from '../pages/AssessmentPayHistory.vue';
import ShoprentPayHistory from '../pages/ShoprentPayHistory.vue';
import GullyOnlineBookingHistory from '../pages/GullyOnlineBookingHistory.vue';
import GullyInvoice from '../pages/GullyONBookingInvoice.vue';
import OnlineBookingHistory from '../pages/OnlineBookingHistory.vue';
import OnlineBookingInvoice from '../pages/OnlineBookingInvoice.vue';
import OtherPayments from '../pages/OtherPayments.vue';
import OtherPaymentsInvoice from '../pages/OtherPaymentsInvoice.vue'
import OtherPaymentsHistory from '../pages/OtherPaymentsHistory.vue'
// import DefaultForm from '@/pages/ShoprentInvoice.vue';OnlineBookingHistory

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home, 
  },

  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
 
  {
    path: "/thank",
    name: "Thank",
    component: Thank,
  },
  {
    path: "/underconstruction",
    name: "UnderConstruction",
    component: UnderConstruction,
  },

  {
    path: "/shoprent",
    name: "Shoprent",
    component: Shoprent,
  },
  {
    path: "/shoprentexist",
    name: "Shoprentexist",
    component: ShoprentExist,
  },
  // {
  //   path: "/shoprentinvoice",
  //   name: "ShoprentInvoice",
  //   component: ShoprentInvoice,
  // },
  {
    path: "/shoprentinvoice2",
    name: "ShoprentInvoice2",
    component: ShoprentInvoice2,
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: ChangePassword
  },
  {
    path: "/cancel",
    name: "Cancel",
    component: Cancel,
  },
  {
    path: "/onlinebooking",
    name: "OnlineBooking",
    component: OnlineBooking,
  },
  {
    path: "/assessmenttax",
    name: "AssessmentTax",
    component: AssessmentTax,
  },
  {
    path: "/assessmenttaxinvoice",
    name: "AssessmentTaxInvoice",
    component: AssessmentTaxInvoice,
  },
  {
    path: "/assessmentpayhistory",
    name: "AssessmentPayHistory",
    component: AssessmentPayHistory,
  },
  {
    path: "/shoprentpayhistory",
    name: "ShoprentPayHistory",
    component: ShoprentPayHistory,
  },
  {
    path: "/gullyonlinebookinghistory",
    name: "GullyOnlineBookingHistory",
    component: GullyOnlineBookingHistory,
  },
  {
    path: "/gullyinvoice",
    name: "GullyInvoice",
    component: GullyInvoice,
  },
  {
    path: "/onlinebookinghistory",
    name: "OnlineBookingHistory",
    component: OnlineBookingHistory,
  },
  {
    path: "/onlinebookinginvoice",
    name: "OnlineBookingInvoice",
    component: OnlineBookingInvoice,
  },
  {
    path: "/otherpayments",
    name: "OtherPayments",
    component: OtherPayments,
  },
  {
    path: "/otherpaymentsinvoice",
    name: "OtherPaymentsInvoice",
    component: OtherPaymentsInvoice,
  },
  {
    path: "/otherpaymentshistory",
    name: "OtherPaymentsHistory",
    component: OtherPaymentsHistory,
  },
  {
    path: '/:pathMatch(.*)*',
    component: Home,
    // https://stackoverflow.com/questions/68504803/how-can-i-make-a-catch-all-other-route-in-vue-router-also-catch-the-url-when-p
  },
];

const router = createRouter({
 history: createWebHistory(),
 routes,
});

// Vue.use(VueRouter);
// const router=new VueRouter({routes,});

export default router;