<template>
      <vue-basic-alert :duration="1000" :closeIn="5000" ref="alert" />
    <section>
    
    <div class="register-container">
        <div class="register-form-container">
            <form id="registerForm" @submit="handleSubmit" novalidate autocomplete="off">
                <div class="heading">
            <span>Local Government Online Payment Management System</span>
            <h3>Change Your Password</h3>
        </div>
                
     <!-- {{ getpassmatch }}/{{ getpassmatch.length }} -->
            
                <div class="row">
                <div class="form-group col-md-12">
              
                    <label for="password"><b>Current Password:</b>
                    </label>
                    <input type="password" name="ucurpass" placeholder="enter your Current Password" id="uAddress" class="form-control"
                        v-model="curpass" />
                    <p class="error-mess" v-if="errorObj.curpassErr.length > 0">{{ errorObj.curpassErr[0] }}</p>
                </div>
              
                </div>
                <div class="row">
                <div class="form-group col-md-12">
                    <label for="uPass"><b>New password:</b>
                    </label>
                    <input type="password" name="uPass" placeholder="enter your password" id="uPass"
                        class="form-control" v-model="pass" />
                    <p class="error-mess" v-if="errorObj.passErr.length > 0">{{ errorObj.passErr[0] }}</p>
                </div>
                

              </div>
                <div class="row">
                <div class="form-group col-md-12">
                    <label for="uPassConfirm"><b> Re-type New password:</b>
                    </label>
                    <input type="password" name="uPassConfirm" placeholder="enter your password again" id="uPassConfirm"
                        class="form-control" v-model="confirm" />
                    <p class="error-mess" v-if="errorObj.confirmErr.length > 0">{{ errorObj.confirmErr[0] }}</p>
                </div>
                </div>

                <div class="form-group">
                    <input type="submit" value="Change" class="btn" />
                    <!-- <p>have an account? <router-link @click="scrollToTop()" to="/login">login</router-link> -->
                    <!-- </p> -->
                </div>
            </form>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert';
import { mapState, mapMutations } from "vuex";
export default {
    name: "Register",

    data() {
        return {
            pass: "", confirm: "", curpass: "" ,
            errorObj: {  passErr: [], confirmErr: [], curpassErr: [] },
            matchUser: undefined,
            getpassmatch: undefined,
            Nic:'',
            

        }
    },

    computed: {
        ...mapState(["user"])
    },
    created(){
      
        this.getSessionData();
    }, 
    methods: {
        ...mapMutations(["setUser"]),

        async MatchUser(nic) {
            let data = await axios.get('/customersdata/' + nic);
            this.matchUser = data.data;
        },
        async getSessionData(){
            
        const data=JSON.parse(sessionStorage.getItem('Userdata'));
        if(data){
            this.Nic=data.Unic
        }
            await this.MatchUser(this.Nic);
            this.setUser(this.matchUser);
        },
        
        async passMatch(id,pass) {
            let data = await axios.get('/customerspass/'+id+'/'+pass);
            this.getpassmatch = data.data;
        },
       

        scrollToTop() {
            window.scrollTo(0, 0);
        },
   

        resetCheckErr: function () {
          
            this.errorObj.passErr = [];
            this.errorObj.confirmErr = [];
            this.errorObj.curpassErr = [];
            
        },

        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },

        checkForm: function () {
            this.resetCheckErr();

            if (!this.curpass) {
                this.errorObj.curpassErr.push('Password is required');
            }
            // Pass validate
            if (!this.pass) {
                this.errorObj.passErr.push('Password is required');
            }
            else {
                if (!/[!@#$%^&*]/.test(this.pass)) {
                    this.errorObj.passErr.push('Password must contain at least 1 special character');
                }

                if (this.pass.length < 8) {
                    this.errorObj.passErr.push('Password must be more than or equal 8 characters');
                }
            }

            // Confirm Pass validate
            if (!this.confirm) {
                this.errorObj.confirmErr.push('Confirm password is required');
            }
            else {
                if (this.pass !== this.confirm) {
                    this.errorObj.confirmErr.push('Confirm password must be match with password');
                }
            }


     
        },

        async handleSubmit(e) {
            this.checkForm();

            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                e.preventDefault();
                await this.passMatch(this.Nic,this.curpass);
             if (!this.getpassmatch) {
                this.errorObj.curpassErr.push("Incorrect Your Current password!")
                }
                else{

                    // this.errorObj.curpassErr.push("yeeeehhhh password!")
                    let data = {
                     
                        cus_password: this.pass
                        
                    }
                    await axios.put("/passUpdate/"+this.Nic,data);
                    this.$refs.alert.showAlert('success','Your Password is Updated!')


                    this.setUser("");
                    // this.name="";
                    // this.nic="",
                    window.sessionStorage.clear();
                    window.sessionStorage.removeItem('User');
                    this.$router.push("/login");
                    // this.pass="";
                    // this.curpass="";
                    // this.confirm="";
                }
            }
        }
    },
    components: {
        VueBasicAlert
    }

};
</script>


<style scoped>
.register-container {
    padding: 2rem 9%;
     min-height: 74vh;
     padding-top: 8rem;
       background-image: url("../assets/images/regbacground.jpg");
    /* width: 100%; */
    /* height: 50%; */
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size:cover;
  padding-left: 28%;
}

.register-container .register-form-container {
    background: #ffffffe1;
      width: 70%;
    
      /* padding-left: 5%; */

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
  
}

.register-container .register-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.6rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
     border: margin-bottom; 
}

.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
    color:#620a0a;
}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 25%;
    text-align: center;
    background: #620a0a;
}

.register-container .register-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group {
    margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
</style>
